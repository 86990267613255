import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";

import CustomTable from "./table";
import Button from "../../../components/button";
import Layout from "../../../components/layouts";
import { Loader } from "../../../components/loader";
import SearchBox from "../../../components/searchbox";
import { Flex, Container } from "../../../components/box";
import { Spacer } from "../../../components/spacer/styles";
import { ToastMessage, type } from "../../../utils/toastr";
import { MarketsTableHead } from "../../../utils/constants";
import ModalFrame from "../../../components/modal/ModalFrame";
import { Styles } from "./styles";
import {
  getMarketRates,
  deleteMarketRatesById,
  addNewMarketRate,
  editMarketRate,
  getRatesFromSource,
} from "../../../services/Markets.service";
import { useAuthState } from "../../../context";
import Add from "./forms/add";
import Edit from "./forms/edit";
import { getRateSource } from "../../../services/Settings.service";

const Index = () => {
  const queryClient = useQueryClient();
  const state = useAuthState();

  const [form] = Form.useForm();
  const [action, setAction] = useState("add");
  const [visible, setVisible] = useState(false);
  const [marketRateId, setMarketRateId] = useState(null);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [rates, setRates] = useState();
  const [loading, setLoading] = useState(false);
  const [initialEditValues, setInitialEditValues] = useState({});

  const result = useQuery("getMarketRates", async () => await getMarketRates());

  const rateSource = useQuery(
    "getRateSource",
    async () => await getRateSource()
  );

  const addMutation = useMutation(
    async (data) => {
      await addNewMarketRate(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getMarketRates");
        ToastMessage(type.SUCCESS, "market rate sucessfully added");
        setVisible(false);
        form.resetFields();
        setRates();
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const editMutation = useMutation(
    async (data) => {
      await editMarketRate(marketRateId, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getMarketRates"]);
        ToastMessage(type.SUCCESS, "market rate sucessfully edited");
        setVisibleEdit(false);
        form.resetFields();
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const deleteMutation = useMutation(
    async (data) => {
      await deleteMarketRatesById(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getMarketRates");
        setDeleteVisible(false);
        ToastMessage(type.SUCCESS, "market rate deleted succesfully");
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setVisibleEdit(false);
    setDeleteVisible(false);
    setAction("add");
    setRates();
    form.resetFields();
  };

  const handleEdit = async (id) => {
    setAction("edit");
    setMarketRateId(id);
    setLoading(true);
    setTimeout(() => setVisibleEdit(true), 200);
    result?.data?.data?.data?.find((item) => {
      if (item.id === id) {
        setInitialEditValues(item);
        const newPair = item?.currency_pair?.replace(/\//g, " ").split(" ");
        getRatesFromSource(newPair[0], newPair[1])
          .then((data) => setRates(data?.data?.data), setLoading(false))
          .catch((error) => {
            ToastMessage(type.ERROR, error.response.data.message);
          });
      }
    });
  };

  const handleInputValueChange = async (e) => {
    const { value } = e;
    const newPair = value.replace(/\//g, " ").split(" ");

    try {
      setLoading(true);
      const data = await getRatesFromSource(newPair[0], newPair[1]);
      setRates(data?.data?.data);
      setLoading(false);
    } catch (error) {
      ToastMessage(type.ERROR, error.response.data.message);
      setLoading(false);
    }
  };

  const handelSubmit = async (values) => {
    const payload = {
      currency_pair: values.currency_pair,
      offset: values.offset,
      selling: Number(values.selling),
      buying: Number(values.buying),
      source_currency: values.source_currency,
      source: values.source,
    };
    if (action === "add") {
      try {
        await addMutation.mutate(payload);
      } catch (error) {
        ToastMessage(type.ERROR, error.response.data.message);
      }
    } else {
      try {
        editMutation.mutate(payload);
      } catch (error) {
        ToastMessage(type.ERROR, error.response.data.message);
      }
    }
  };

  const handleSetDelete = (id) => {
    setDeleteVisible(true);
    setMarketRateId(id);
  };

  const handleDelete = async () => {
    try {
      await deleteMutation.mutate(marketRateId);
    } catch (error) {
      ToastMessage(type.ERROR, error.response.data.message);
    }
  };

  const currencyPair = state?.currencyPair;

  useEffect(() => {
    queryClient.refetchQueries(["getAllCurrencyPair"]);
    return () => {};
  }, []);

  if (result.isLoading) {
    return <Loader full />;
  }

  if (result.isError) {
    return <span>Error: {result?.error.message}</span>;
  }
  return (
    <>
      <Layout title={"Markets"} bgColor={"#fff"}>
        <Styles>
          <Spacer hieght="40px" />
          <Flex pad="20px" justifyContent="flex-start">
            <h3>Market rate proposal</h3>
          </Flex>
          <Flex
            justifyContent="space-between"
            pad="20px"
            className="input_container"
          >
            <SearchBox placeholder={"Search asset pair"} width="323px" />

            <Container width={"max-content"}>
              <Button
                text={"Add new"}
                bgColor={"#1A2CCE"}
                color={"#fff"}
                size="sm"
                type="button"
                borderRadius={"3px"}
                onClick={showModal}
              />
            </Container>
          </Flex>
          <Spacer height="20px" />
          <CustomTable
            theadBkColor="#F1F4F8"
            bottomGap="5px"
            tableHead={MarketsTableHead}
            tableBody={result?.data?.data?.data}
            rowHovColor="#d2ccc626"
            theadColor="#000A62"
            tbodyColor="#1A1F36"
            handleEdit={handleEdit}
            handleDelete={handleSetDelete}
            currencies={state?.currencies}
          />
        </Styles>
      </Layout>

      <ModalFrame
        visible={visible}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            bgColor={"#ffffff"}
            size="md"
            type="button"
            onClick={handleCancel}
          />,
          <Button
            Styles
            text={action === "add" ? "Add" : "Update"}
            bgColor={"#1A2CCE"}
            color="#FFFFFF"
            size="md"
            type="submit"
            key="submit"
            loading={addMutation.isLoading || editMutation.isLoading}
            form="marketForm"
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          />,
        ]}
      >
        <Add
          handelSubmit={handelSubmit}
          handleInputValueChange={handleInputValueChange}
          action={action}
          currencyPair={currencyPair}
          loading={loading}
          rates={rates}
          form={form}
          source={rateSource?.data?.data?.data}
        />
      </ModalFrame>

      <ModalFrame
        visible={visibleEdit}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            bgColor={"#ffffff"}
            size="md"
            type="button"
            onClick={handleCancel}
          />,
          <Button
            Styles
            text={action === "add" ? "Add" : "Update"}
            bgColor={"#1A2CCE"}
            color="#FFFFFF"
            size="md"
            type="submit"
            key="submit"
            loading={addMutation.isLoading || editMutation.isLoading}
            form="marketForm"
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          />,
        ]}
      >
        <Edit
          handelSubmit={handelSubmit}
          handleInputValueChange={handleInputValueChange}
          action={action}
          currencyPair={currencyPair}
          loading={loading}
          rates={rates}
          initialEditValues={initialEditValues}
          source={rateSource?.data?.data?.data}
        />
      </ModalFrame>

      <ModalFrame
        visible={deleteVisible}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            bgColor={"#ffffff"}
            size="md"
            type="button"
            onClick={handleCancel}
          />,
          <Button
            Styles
            text={"Confirm"}
            bgColor={"#1A2CCE"}
            color="#FFFFFF"
            size="md"
            type="button"
            loading={deleteMutation.isLoading}
            onClick={handleDelete}
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          />,
        ]}
      >
        <h3>Confirm Delete</h3>
        <p>
          This action is irreversible, Are you sure you want to delete the rate?
        </p>
      </ModalFrame>
    </>
  );
};

export default Index;
