import React from 'react';
import Layout from '../../../components/layouts/index';
import RouterTabs from '../../../components/Tabs/routerTab';

import { Styles } from './styles';

import Rejected from './rejected/index';
import Requested from './request/index';
import Completed from './complete/index';

const Index = () => {
	const links = [
		{
			path: '/dashboard/refund',
			title: 'Rejected'
		},
		{
			path: '/dashboard/refund/request',
			title: 'Requested'
		},

		{
			path: '/dashboard/refund/complete',
			title: 'Completed'
		}
	];

	const routes = [
		{
			path: '/',
			component: <Rejected />
		},
		{
			path: '/request',
			component: <Requested />
		},
		{
			path: '/complete',
			component: <Completed />
		}
	];

	return (
		<Styles>
			<Layout title={'Refunds'}>
				<RouterTabs routes={routes} links={links} type='refund' />
			</Layout>
		</Styles>
	);
};

export default Index;
