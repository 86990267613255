import React, { useState } from 'react';

import { Checkbox } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Container, Flex } from '../../../components/box';
import Button from '../../../components/button/index';
import Filters from '../../../components/filters/Filters';
import Layout from '../../../components/layouts/index';
import { Loader } from '../../../components/loader/index';
import ModalFrame from '../../../components/modal/ModalFrame';
import Search from '../../../components/search/index';
import { Spacer } from '../../../components/spacer/styles';
import {
	clearLimitMerchant,
	getAllMerchants,
	getTotalMerchantSignup,
	getTotalMerchantSummaryCount,
	getTotalSuspendedMerchantSummary,
	suspendMerchant,
	unsupendMerchant
} from '../../../services/Merchants.service';
import { MerchantTableHead } from '../../../utils/constants';
import { formatType } from '../../../utils/helpers';
import { ToastMessage, type } from '../../../utils/toastr';
import {
	ApproveTransactionSummary,
	QRScreen
} from '../approvals/approvalTransactionSummary/ApproveTransactionSummary';
import Overview from './overveiw/index';
import { Container1, Styles } from './styles';
import CustomTable from './table/index';

const Merchants = () => {
	const date = new Date();
	const y = date.getFullYear();
	const m = date.getMonth();

	const firstDay = new Date(y, m, 1);
	const lastDay = new Date(y, m + 1, 0);

	const queryClient = useQueryClient();

	const [resetLogin, setResetLogin] = useState(false);
	const [qrCode, setQrCode] = useState(false);
	const [resendEVM, setResendEVM] = useState(false);
	const [suspendUser, setSuspendUser] = useState(false);
	const [clearLimit, setClearLimit] = useState(false);
	const [id, setId] = useState(null);
	const [action, setAction] = useState('');
	const [checkedValue, setcheckedValue] = useState(true);

	const [statusValue, setStatusValue] = useState('All');
	const [countryValue, setCountryValue] = useState('All');
	const [kycValue, setKycValue] = useState('All');
	const [input, setInput] = useState('');
	const [clicked, setClicked] = useState('false');

	const [filter, setFilter] = useState({});

	const merchants = useQuery(
		'getAllMerchants',
		async () => await getAllMerchants(filter),
		{
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const merchantsCount = useQuery(
		'getTotalMerchantSummaryCount',
		async () => await getTotalMerchantSummaryCount()
	);

	const merchantsSuspended = useQuery(
		'getTotalSuspendedMerchantSummary',
		async () => await getTotalSuspendedMerchantSummary()
	);

	const merchantsSingupMonthly = useQuery(
		'getTotalMerchantSignup',
		async () =>
			await getTotalMerchantSignup(formatType(firstDay), formatType(lastDay))
	);

	const suspendMutation = useMutation(
		async (data) => {
			await suspendMerchant(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['getAllMerchants']);
				queryClient.invalidateQueries(['getTotalSuspendedMerchantSummary']);
				ToastMessage(type.SUCCESS, 'merchant has been suspended');

				setTimeout(() => setSuspendUser(false), 1000);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const unsuspendMutation = useMutation(
		async (data) => {
			await unsupendMerchant(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['getAllMerchants']);
				queryClient.invalidateQueries(['getTotalSuspendedMerchantSummary']);
				ToastMessage(type.SUCCESS, 'merchant has been unsuspended');
				setTimeout(() => setSuspendUser(false), 1000);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const resetLoginMutation = useMutation(
		async (data) => {
			// await resetLoginMerchant(id, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['getAllMerchants']);
				ToastMessage(type.SUCCESS, 'merchant login reset successfully');
				setTimeout(() => setResetLogin(false), 1000);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const ClearLimitMutation = useMutation(
		async (data) => {
			await clearLimitMerchant(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['getAllMerchants']);
				ToastMessage(type.SUCCESS, 'Merchant limit cleared successfully');
				setTimeout(() => setClearLimit(false), 1000);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const showSuspendModal = (e, action) => {
		setSuspendUser(true);
		setAction(action);
		setId(e);
	};
	const handleResendEVM = (e) => {
		setResendEVM(true);
		setAction('verify');
		// setAction(action);
		setId(e);
	};

	const showClearLimitModal = (e) => {
		setClearLimit(true);
		setId(e);
	};

	const showResetLoginModal = (e) => {
		setResetLogin(true);
		setId(e);
	};

	const handleClearLimit = () => {
		ClearLimitMutation.mutate(id);
	};

	const handleCheck = (data) => {
		setcheckedValue(data.target.checked);
	};

	const handleResetLogin = () => {
		resetLoginMutation.mutate({ mail: checkedValue });
	};

	const handleSuspend = () => {
		if (action === 'active') {
			suspendMutation.mutate(id);
		} else if (action === 'suspended') {
			unsuspendMutation.mutate(id);
		}
	};

	const handleqrCode = () => {
		setQrCode(true);
	};
	const handleCancel = () => {
		setSuspendUser(false);
		setClearLimit(false);
		setResetLogin(false);
		setResendEVM(false);
	};

	const handleCancelqr = () => {
		setQrCode(false);
	};

	const handleSelectStatus = (e) => {
		setStatusValue(e);
		setFilter({ ...filter, status: e });
	};

	const handleSelectKyc = (e) => {
		setKycValue(e);
		setFilter({ ...filter, kyc: e });
	};

	const handleSelectCountry = (e) => {
		setCountryValue(e);
		setFilter({ ...filter, country: e });
	};

	const handleSearch = (e) => {
		const { value } = e.target;
		setInput(value);
		setFilter({ ...filter, search: value });
	};

	const handleSubmit = () => {
		if (filter.status === 'All') {
			delete filter.status;
		}
		if (filter.country === 'All') {
			delete filter.country;
		}
		if (filter.kyc === 'All') {
			delete filter.kyc;
		}
		setClicked('true');
		merchants.refetch(filter);
	};

	const handlePageChange = (page) => {
		setFilter({ ...filter, page: page });
		setTimeout(() => merchants.refetch());
	};

	// if (merchants.isLoading) {
	// 	return <Loader full />;
	// }

	if (merchants.isError || merchantsCount.isError) {
		return (
			<span>
				Error: {merchants.error.message || merchantsCount.error.message}
			</span>
		);
	}

	return (
		<Styles>
			<Layout title='Merchants'>
				<Spacer height='20px' />
				<Overview
					merchantsCount={merchantsCount?.data?.data?.data}
					merchantsSuspended={merchantsSuspended?.data?.data?.data}
					merchantsSingupMonthly={merchantsSingupMonthly?.data?.data?.data}
					loading={
						merchantsCount.isLoading ||
						merchantsSingupMonthly.isLoading ||
						merchantsSuspended.isLoading
					}
				/>
				<Spacer height='30px' />
				<Container1>
					<Container className='input_container' width={'20%'}>
						<Search
							placeholder={'Search by name.'}
							input={input}
							setInput={setInput}
							handleSearch={handleSearch}
						/>
					</Container>

					<Filters
						source={'merchants'}
						handleSelectCountry={handleSelectCountry}
						onChangeStatus={handleSelectStatus}
						handleSelectKyc={handleSelectKyc}
						refetch={handleSubmit}
						statusValue={statusValue}
						countryValue={countryValue}
						kycValue={kycValue}
						loading={clicked === 'true' && merchants.isRefetching}
					/>
				</Container1>
				{merchants?.isFetching ? (
					<Loader full />
				) : (
					<CustomTable
						theadBkColor='#F1F4F8'
						bottomGap='10px'
						tableHead={MerchantTableHead}
						tableBody={merchants?.data?.data?.data?.data}
						rowHovColor='#d2ccc626'
						theadColor='#8e8e8e'
						tbodyColor='#141414'
						paginator
						showClearLimitModal={showClearLimitModal}
						showSuspendModal={showSuspendModal}
						showResetLoginModal={showResetLoginModal}
						handlePageChange={handlePageChange}
						meta={merchants?.data?.data?.data?.meta}
						handleResendEVM={handleResendEVM}
					/>
				)}
			</Layout>
			<ModalFrame
				visible={clearLimit}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
					/>,
					<Button
						Styles
						text={'Confirm'}
						bgColor={'#A50000'}
						color='#FFFFFF'
						size='md'
						type='button'
						onClick={handleClearLimit}
						loading={ClearLimitMutation.isLoading}
					/>
				]}
			>
				<h3 style={{ textAlign: 'center' }}>Clear limit</h3>
				<p>Are you sure you want to clear limit</p>
			</ModalFrame>
			<ModalFrame
				visible={resetLogin}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
					/>,
					<Button
						Styles
						text={'Confirm'}
						bgColor={'#A50000'}
						color='#FFFFFF'
						size='md'
						type='button'
						onClick={handleResetLogin}
						loading={resetLoginMutation.isLoading}
					/>
				]}
			>
				<h3 style={{ textAlign: 'center' }}>Reset login</h3>
				<p>Are you sure you want to reset login</p>
				<Flex
					justifyContent='flex-start'
					pad='20px 0 0 70px'
					className='checkbox'
				>
					<Checkbox defaultChecked={checkedValue} onChange={handleCheck}>
						Notify Merchant
					</Checkbox>
				</Flex>
			</ModalFrame>
			<ModalFrame
				visible={suspendUser}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
					/>,
					<Button
						Styles
						text={'Confirm'}
						bgColor={'#A50000'}
						color='#FFFFFF'
						size='md'
						type='button'
						onClick={handleSuspend}
						loading={suspendMutation.isLoading || unsuspendMutation.isLoading}
					/>
				]}
			>
				<h3 style={{ textAlign: 'center' }}>
					{action === 'active' ? 'Suspend user' : 'Unsuspend User'}
				</h3>
				<p>
					{`Are you sure you want to ${action === 'active' ? 'suspend' : 'unsuspend'
						} the user`}
				</p>
			</ModalFrame>
			<ModalFrame visible={resendEVM} handleCancel={handleCancel} footer={null}>
				<ApproveTransactionSummary
					id={id}
					handleQrScreen={handleqrCode}
					swapAction={action}
					setVisible={setResendEVM}
				/>
				<ModalFrame
					visible={qrCode}
					handleCancel={handleCancelqr}
					footer={null}
				>
					<QRScreen />
				</ModalFrame>
			</ModalFrame>
		</Styles>
	);
};

export default Merchants;
