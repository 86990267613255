import React from 'react';
import { Spacer } from '../../../../components/spacer/styles';
import { Styles } from './styles';
import CustomTable from '../table/index';
import { useQuery } from 'react-query';
import { getAllPendingBalances } from '../../../../services/Balance.service';
import { Loader } from '../../../../components/loader/index';
import { useAuthState } from '../../../../context';
import { Select } from 'antd';
import Filters from '../../../../components/filters/Filters';
import { useState } from 'react';
import { Flex } from '../../../../components/box';

const Pending = () => {
	const state = useAuthState();
	const [filter, setFilter] = React.useState({});
	const [currencies, setCurrencies] = useState([]);
	const [allData, setAllData] = useState([]);
	const [type, setType] = useState('All');
	const [clicked, setClicked] = useState('');
	const balances = useQuery(
		'getAllPendingBalances',
		async () => await getAllPendingBalances(filter),
		{
			onSuccess: (res) => {
				setAllData(res?.data?.data);
				setCurrencies(res?.data?.data?.map((item) => item?.currency));
			},
			onError: (err) => console.log(err)
		}
	);
	const [filteredData, setFilteredData] = useState([]);

	if (balances?.isFetching) {
		return <Loader />;
	}

	if (balances.status === 'error') {
		return <span>Error: {balances.error.message}</span>;
	}

	const handlePendingBalanceChange = (e) => {
		const { value } = e.target;
		setType(value);
		setFilter({ ...filter, type: value });
	};

	const handleSubmit = () => {
		if (filter.type === 'All') {
			delete filter.type;
		}
		setClicked('true');
		setFilteredData([]);
		balances.refetch(filter);
	};

	const onChange = (e) => {
		if (e === 'All') {
			setFilteredData(allData);
		} else {
			setFilteredData(allData?.filter((item) => item?.currency?.match(e)));
		}
	};

	return (
		// <Layout title={'Balances'}>
		<Styles>
			<Spacer height='30px' />

			<Flex>
				<Flex flexDir='column' alignItem='flex-start'>
					<Select
						onChange={onChange}
						style={{
							width: '20%'
							// height: '46px'
						}}
						placeholder='Filter By Currency'
					>
						<Select.Option value='All'>All</Select.Option>
						{currencies?.map((item) => (
							<Select.Option value={item} key={item}>
								{item}
							</Select.Option>
						))}
					</Select>
				</Flex>

				<Filters
					source='balance'
					handlePendingBalanceChange={handlePendingBalanceChange}
					options={type}
					refetch={handleSubmit}
					loading={clicked === 'true' && balances.isRefetching}
				/>
			</Flex>

			<Spacer height='30px' />
			<CustomTable
				theadBkColor='#F1F4F8'
				bottomGap='10px'
				tableHead={dataHead}
				tableBody={filteredData?.length === 0 ? allData : filteredData}
				rowHovColor='#d2ccc626'
				theadColor='#000A62'
				tbodyColor='#141414'
				currencies={state?.currencies}

			// pageSize={10}
			// paginator

			//   meta={approvalsFiat?.data?.data?.data.meta}
			//   handlePageChange={handlePageChange}
			/>
		</Styles>
		// </Layout>
	);
};

export default Pending;

const dataHead = ['currency', 'balance', 'time left'];
