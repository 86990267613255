import { Form, InputNumber, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AddNew } from "../../../../../assets";
import { Container, Flex } from "../../../../../components/box";
import Button from "../../../../../components/button";
import ModalFrame from "../../../../../components/modal/ModalFrame";
import {
  getStaff,
  getStaffs,
  revokeApprover,
  setApproverNumber,
} from "../../../../../services/Settings.service";
import { ToastMessage, type } from "../../../../../utils/toastr";
import { ApproveTransactionSummary } from "../../../approvals/approvalTransactionSummary/ApproveTransactionSummary";
import { FormContainer, Styles } from "./styles";
import CustomTable from "./table";
import DebounceSelect from "../../../fees/cross-fees/DebounceSelect";
import { getMerchantSearch } from "../../../../../services/Others.service";

const Index = ({ settings, result }) => {
  // const { settings } = useAuthState();

  const queryClient = useQueryClient();
  const [visible, setVisible] = React.useState(false);
  const [confirmVisible, setConfirmVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [id, setId] = useState(null);
  const [otpVisible, setOtpVisible] = useState(false);
  const [formData, setFormData] = useState({});
  const [userId, setUserId] = useState();

  const [approverNum, setApproverNum] = useState(
    settings && settings?.min_approvers
  );

  // const { data: staffs } = useQuery("getStaffs", async () => await getStaffs());

  const approverNumberMutation = useMutation(
    async (data) => {
      await setApproverNumber(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getSettings");
        ToastMessage(type.SUCCESS, "Admin minimum approvals set successfully");
        setTimeout(() => setVisible(false), 500);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const RevokeMutation = useMutation(
    async (data) => {
      await revokeApprover(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getApprovers");
        queryClient.invalidateQueries("getUserprofile");
        ToastMessage(type.SUCCESS, "Admin access revoked successfully");
        setTimeout(() => setVisible(false), 500);
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const showModal = (id) => {
    setVisible(true);
    setId(id);
  };

  const showConfirmModal = () => {
    setConfirmVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleChange = (e) => {
    setApproverNum(e);
  };

  const handleSubmitApprover = () => {
    approverNumberMutation.mutate({
      value: Number(approverNum),
    });
  };

  const handleSubmit = (values) => {
    setFormData(values);
    setConfirmVisible(false);
    setOtpVisible(true);
    console.log(values);
    // mutation.mutate(values);
  };

  const handleCancel = () => {
    setVisible(false);
    setConfirmVisible(false);
    setOtpVisible(false);
  };

  const handleRevoke = () => {
    RevokeMutation.mutate(id);
  };

  const approvers = result?.data?.data?.data;
  return (
    <>
      <Styles>
        <Container>
          <Flex
            flexDir="row"
            justifyContent="space-between"
            className="overall"
          >
            <div>
              <h3>Overall Access</h3>
              <p>Number of signatures to approve a withdrawal</p>
            </div>
            <div>
              <InputNumber
                min={0}
                type="number"
                onChange={handleChange}
                defaultValue={approverNum}
              />
            </div>
          </Flex>

          <h3>Approval Signatories</h3>
          <hr />
          <CustomTable
            theadBkColor="#F1F4F8"
            tableHead={["Name", "Type", "Email", ""]}
            tableBody={approvers}
            rowHovColor="#d2ccc626"
            theadColor="#8e8e8e"
            tbodyColor="#141414"
            // pageSize={5}
            revokeApprover={showModal}
          />

          <Flex flexDir="row" justifyContent="space-between" className="btn">
            <Flex
              flexDir="row"
              justifyContent="flex-start"
              width="40%"
              className="add-btn"
            >
              <AddNew />
              <Button
                Styles
                text={"Add new signatory"}
                color={"#1A2CCE"}
                size="md"
                type="button"
                onClick={showConfirmModal}
              />
            </Flex>

            <Button
              Styles
              text={"Save changes"}
              bgColor={"#1A2CCE"}
              color={"#FFFFFF"}
              size="md"
              onClick={handleSubmitApprover}
              loading={approverNumberMutation?.isLoading}
              className="save"
            />
          </Flex>
        </Container>
      </Styles>
      <ModalFrame
        visible={visible}
        handleOk={handleOk}
        confirmLoading={confirmLoading}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            color={"#404040"}
            bgColor={"#FFFFFF"}
            size="md"
            type="button"
            border={"0.7px solid #BFBFBF"}
            key="back"
            onClick={handleCancel}
            marginRight={5}
          />,
          <Button
            Styles
            text={"Revoke"}
            bgColor={"#1A2CCE"}
            color={"#FFFFFF"}
            size="md"
            type="button"
            key="submit"
            onClick={handleRevoke}
            loading={RevokeMutation.isLoading}
          />,
        ]}
      >
        <FormContainer>
          <ConfirmRevoke handleRevoke={handleRevoke} />
        </FormContainer>
      </ModalFrame>
      <ModalFrame
        visible={confirmVisible}
        handleOk={handleOk}
        confirmLoading={confirmLoading}
        handleCancel={handleCancel}
        footer={null}
      >
        <FormContainer>
          <AddSignatoryForm
            // staffs={staffs?.data?.data?.data}
            setVisible={setConfirmVisible}
            handleSubmit={handleSubmit}
          />
        </FormContainer>
      </ModalFrame>

      <ModalFrame
        visible={otpVisible}
        handleCancel={handleCancel}
        footer={null}
      >
        <ApproveTransactionSummary
          // id={params.merchant}
          // handleQrScreen={handleqrCode}
          // swapAction={status}
          status={"initiate/approve"}
          setVisible={setOtpVisible}
          signatoryFormData={formData}
          // showConfirmModal={showConfirmModal}
        />
        {/* <ModalFrame
					// visible={qrCode}
					// handleCancel={handleCancelqr}
					footer={null}
				>
					<QRScreen />
				</ModalFrame> */}
      </ModalFrame>
    </>
  );
};

export default Index;

const ConfirmRevoke = () => (
  <>
    <Container>
      <Flex flexDir="column" justifyContent="center" alignItems="center">
        <h3>Revoke approval access</h3>
        <p>
          By revoking access, this user will no longer
          <br />
          have access to approving withdrawals.
        </p>
      </Flex>
    </Container>
  </>
);

const AddSignatoryForm = ({ handleSubmit }) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (search !== "") {
      setIsLoading(true);
      getStaff(search)
        .then((res) => {
          console.log(res?.data?.data?.data);
          setValue(res?.data?.data?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          ToastMessage(type.ERROR, err?.response?.data?.message);
          setIsLoading(false);
        });
    }
  }, [search]);
  const fetchMerchantsList = (username) => {
    console.log("fetching user", username);
    if (username !== "" || username !== undefined) {
      return getStaff(username);
      // ?.catch((err) =>
      // );
    } else {
      return [];
    }
  };

  return (
    <>
      <h3 className="signatory-header">Add new signatory</h3>

      <Form
        layout={"vertical"}
        form={form}
        initialValues={{
          layout: "vertical",
          size: "large",
        }}
        size="large"
        // id="signatory"
        onFinish={handleSubmit}
      >
        <Form.Item name="userId" label="Select team member">
          <Select
            size="large"
            style={{ width: "100%" }}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Search Approvers"
            onSearch={(e) => setSearch(e)}
            loading={isLoading}
            notFoundContent={null}
          >
            {value?.map((item) => (
              <Select.Option key={item.id} value={item?.id}>
                {item.full_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="type" label="Select type">
          <Select
            size="large"
            style={{ width: "100%" }}
            placeholder="Select type"
          >
            <Select.Option value="initiator">Initiator</Select.Option>
            <Select.Option value="approver">Approver</Select.Option>
          </Select>
        </Form.Item>
        <Button
          Styles
          text={"Add as signatory"}
          bgColor={"#1A2CCE"}
          color={"#FFFFFF"}
          fullwidth
          size="md"
          type="submit"
          className="add-as"
          // loading={mutation.isLoading}
        />
      </Form>
    </>
  );
};
