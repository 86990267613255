import React, { useEffect } from "react";
import { Form } from "antd";
import { useParams } from "react-router-dom";

import CustomTable from "../../../markets/table";
import Button from "../../../../../components/button";
import { Loader } from "../../../../../components/loader";
import SearchBox from "../../../../../components/searchbox";
import { Styles } from "./styles";
import { Flex, Container } from "../../../../../components/box";
import { Spacer } from "../../../../../components/spacer/styles";
import { ToastMessage, type } from "../../../../../utils/toastr";
import { MarketsTableHead } from "../../../../../utils/constants";
import ModalFrame from "../../../../../components/modal/ModalFrame";
import {
  getMarketRatesForMerchant,
  addMarketRatesForMerchant,
  deleteMarketRatesById,
  editMarketRate,
  getRatesFromSource,
} from "../../../../../services/Markets.service";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuthState } from "../../../../../context";
import { FormLayout } from "./form";
import { getRateSource } from "../../../../../services/Settings.service";
import { EditFeesForm } from "./editFeesForm";
import { merchantSwapFees } from "../../../../../services/Merchants.service";

const Index = () => {
  const queryClient = useQueryClient();
  const state = useAuthState();
  let params = useParams();

  const [form] = Form.useForm();
  const [action, setAction] = React.useState("add");
  const [visible, setVisible] = React.useState(false);
  const [visibleFees, setVisibleFees] = React.useState(false);
  const [marketRateId, setMarketRateId] = React.useState(null);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [deleteVisible, setDeleteVisible] = React.useState(false);
  const [rates, setRates] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [initialEditValues, setInitialEditValues] = React.useState({});

  const result = useQuery(
    "getMarketRatesForMerchant",
    async () => await getMarketRatesForMerchant(params.merchant)
  );

  const rateSource = useQuery(
    "getRateSource",
    async () => await getRateSource()
  );

  const addMutation = useMutation(
    async (data) => {
      await addMarketRatesForMerchant(params.merchant, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getMarketRatesForMerchant");
        ToastMessage(type.SUCCESS, "market rate sucessfully added");
        setVisible(false);
        form.resetFields();
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const editMutation = useMutation(
    async (data) => {
      await editMarketRate(marketRateId, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getMarketRatesForMerchant");
        ToastMessage(type.SUCCESS, "market rate sucessfully edited");
        setVisibleEdit(false);
        form.resetFields();
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const editFeeMutation = useMutation(
    async (data) => {
      await merchantSwapFees(params.merchant, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getMarketRatesForMerchant"]);
        ToastMessage(type.SUCCESS, "fees sucessfully edited");
        setVisibleFees(false);
        form.resetFields();
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const deleteMutation = useMutation(
    async (data) => {
      await deleteMarketRatesById(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getMarketRatesForMerchant");
        setDeleteVisible(false);
        ToastMessage(type.SUCCESS, "market rate deleted succesfully");
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  function showModal() {
    setVisible(true);
  }

  const handleCancel = () => {
    setVisible(false);
    setVisibleEdit(false);
    setDeleteVisible(false);
    setVisibleFees(false);
    setInitialEditValues({});
    setAction("add");
    form.resetFields();
    setRates();
  };

  const handleEditFees = (id) => {
    setTimeout(() => setVisibleFees(true), 200);
    result?.data?.data?.data?.find((item) => {
      if (item.id === id) {
        setInitialEditValues(item?.merchant_fee);
      }
    });
  };

  const handleEdit = async (id) => {
    setAction("edit");
    setMarketRateId(id);
    setLoading(true);
    setTimeout(() => setVisibleEdit(true), 200);
    result?.data?.data?.data?.find((item) => {
      if (item.id === id) {
        setInitialEditValues(item);
        const newPair = item?.currency_pair?.replace(/\//g, " ").split(" ");
        getRatesFromSource(newPair[0], newPair[1])
          .then((data) => setRates(data?.data?.data), setLoading(false))
          .catch((error) => {
            ToastMessage(type.ERROR, error.response.data.message);
          });
      }
    });
  };

  const handleInputValueChange = async (e) => {
    const { value } = e;
    const newPair = value.replace(/\//g, " ").split(" ");

    try {
      setLoading(true);
      const data = await getRatesFromSource(newPair[0], newPair[1]);
      setRates(data?.data?.data);
      setLoading(false);
    } catch (error) {
      ToastMessage(type.ERROR, error.response.data.message);
      setLoading(false);
    }
  };

  const handelSubmit = async (values) => {
    const payload = {
      currency_pair: values.currency_pair,
      offset: values.offset,
      selling: Number(values.selling),
      buying: Number(values.buying),
      source_currency: values.source_currency,
      source: values.source,
    };
    if (action === "add") {
      try {
        await addMutation.mutate(payload);
      } catch (error) {
        ToastMessage(type.ERROR, error.response.data.message);
      }
    } else {
      try {
        editMutation.mutate(payload);
      } catch (error) {
        ToastMessage(type.ERROR, error.response.data.message);
      }
    }
  };

  const handelSubmitEditFee = async (values) => {
    const payload = {
      from_currency: initialEditValues.from_currency,
      to_currency: initialEditValues.to_currency,
      ...values,
    };
    editFeeMutation.mutate(payload);
  };

  const handleSetDelete = (id) => {
    setDeleteVisible(true);
    setMarketRateId(id);
  };

  const handleDelete = async () => {
    try {
      await deleteMutation.mutate(marketRateId);
    } catch (error) {
      ToastMessage(type.ERROR, error.response.data.message);
    }
  };

  useEffect(() => {
    queryClient.refetchQueries(["getAllCurrencyPair"]);
    return () => {};
  }, []);
  if (result.isLoading) {
    return <Loader />;
  }

  if (result.isError) {
    return <span>Error: {result?.error.message}</span>;
  }

  const currencyPair = state?.currencyPair;

  return (
    <>
      <Styles>
        <Spacer hieght="40px" />
        <Flex pad="20px" justifyContent="flex-start">
          <h3>Market rate proposal</h3>
        </Flex>
        <Flex
          justifyContent="space-between"
          pad="20px"
          className="input_container"
        >
          <SearchBox placeholder={"Search asset pair"} width="323px" />

          <Container width={"max-content"}>
            <Button
              text={"Add new"}
              bgColor={"#1A2CCE"}
              color={"#fff"}
              size="sm"
              type="button"
              borderRadius={"3px"}
              onClick={showModal}
            />
          </Container>
        </Flex>
        <Spacer height="20px" />
        <CustomTable
          theadBkColor="#F1F4F8"
          bottomGap="5px"
          tableHead={MarketsTableHead}
          tableBody={result?.data?.data?.data}
          rowHovColor="#d2ccc626"
          theadColor="#000A62"
          tbodyColor="#1A1F36"
          handleEdit={handleEdit}
          handleDelete={handleSetDelete}
          handleEditFees={handleEditFees}
          editFee
        />
      </Styles>

      <ModalFrame
        visible={action === "add" ? visible : visibleEdit}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            bgColor={"#ffffff"}
            size="md"
            type="button"
            onClick={handleCancel}
          />,
          <Button
            Styles
            text={action === "add" ? "Add" : "Update"}
            bgColor={"#1A2CCE"}
            color="#FFFFFF"
            size="md"
            type="submit"
            key="submit"
            loading={addMutation.isLoading || editMutation.isLoading}
            form="marketForm"
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          />,
        ]}
      >
        <FormLayout
          handelSubmit={handelSubmit}
          handleInputValueChange={handleInputValueChange}
          action={action}
          currencyPair={currencyPair}
          loading={loading}
          rates={rates}
          form={form}
          rateSource={rateSource?.data?.data?.data}
          initialEditValues={initialEditValues}
        />
      </ModalFrame>
      <ModalFrame
        visible={deleteVisible}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            bgColor={"#ffffff"}
            size="md"
            type="button"
            onClick={handleCancel}
          />,
          <Button
            Styles
            text={"Confirm"}
            bgColor={"#1A2CCE"}
            color="#FFFFFF"
            size="md"
            type="button"
            loading={deleteMutation.isLoading}
            onClick={handleDelete}
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          />,
        ]}
      >
        <h3>Confirm Delete</h3>
        <p>
          This action is irreversible, Are you sure you want to delete the rate?
        </p>
      </ModalFrame>
      <ModalFrame
        visible={visibleFees}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            bgColor={"#ffffff"}
            size="md"
            type="button"
            onClick={handleCancel}
          />,
          <Button
            Styles
            text={"Confirm"}
            bgColor={"#1A2CCE"}
            color="#FFFFFF"
            size="md"
            type="submit"
            form="editForm"
            loading={editFeeMutation.isLoading}
          />,
        ]}
        width={600}
      >
        <EditFeesForm
          handelSubmitEditFee={handelSubmitEditFee}
          initialEditValues={initialEditValues}
        />
      </ModalFrame>
    </>
  );
};

export default Index;
