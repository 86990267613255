
import { useState } from "react";
import { useQuery } from "react-query";
import CustomTable from "./table";
import { Container1 } from "./styles";
import Search from "../../../../components/search";
import CreateNewAccount from "../FxOpsAccountDetails";
import { Loader } from "../../../../components/loader";
import { Container } from "../../../../components/box";
import Filters from "../../../../components/filters/Filters";
import { CryptoAccountDetails } from "../accountDetail/index";
import ModalFrame from "../../../../components/modal/ModalFrame";
import { getFxOpsTransactions } from "../../../../services/fxOps.services";
import { formatType } from "../../../../utils/helpers";

const Transactions = () => {
  const [visible, setVisible] = useState(false);
  const [visibleCrypto, setVisibleCrypto] = useState(false);

  const [value, setValue] = useState("All");
  const [currency, setCurrency] = useState("All");
  const [statusValue, setStatusValue] = useState("All");
  const [selectedDateTo, setSelectedDateTo] = useState("All");
  const [selectedDateFrom, setSelectedDateFrom] = useState("All");
  const [clicked, setClicked] = useState("false");
  const [filter, setFilter] = useState({ page: 1 });
  const [input, setInput] = useState("");

  const FxOpsTransactions = useQuery(
    "getFxOpsTransactions",
    async () => await getFxOpsTransactions(filter)
  );

  const showDetails = () => {
    setVisible(true);
  };

  const showDetailsCrypto = () => {
    setVisibleCrypto(true);
  };
  const handleCancel = () => {
    setVisible(false);
    setVisibleCrypto(false);
  };

  const onChange = (e) => {
    const { value } = e.target;
    const splitDate = value.split(" - ");
    setValue(value);
    setFilter({ ...filter, from: splitDate[0], to: splitDate[1] });
  };

  const onChangeStatus = (e) => {
    const { value } = e.target;
    setStatusValue(value);
    setFilter({ ...filter, status: value });
  };

  const handleSelectFromChange = (e, dateString) => {
    setSelectedDateFrom(formatType(dateString));
    setFilter({ ...filter, from: formatType(dateString) });
  };

  const handleSelectToChange = (e, dateString) => {
    setSelectedDateTo(formatType(dateString));
    setFilter({ ...filter, to: formatType(dateString) });
  };

  const handleCurrencyChange = (e) => {
    const { value } = e.target;
    setCurrency(value);
    setFilter({ ...filter, currency: value });
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setInput(value);
    setFilter({ ...filter, ref: value });
  };

  const handlePageChange = (page) => {
    setFilter({ ...filter, page: page });
    setTimeout(() => FxOpsTransactions.refetch());
  };

  const handleSubmit = () => {
    if (filter.from === "All" || undefined) {
      delete filter.from;
    }
    if (filter.to === "All" || undefined) {
      delete filter.to;
    }
    if (filter.currency === "All") {
      delete filter.currency;
    }
    if (filter.status === "All") {
      delete filter.status;
    }
    setClicked("true");
    FxOpsTransactions.refetch(filter);
  };

  if (FxOpsTransactions.isLoading) {
    return <Loader />;
  }

  if (FxOpsTransactions.isError) {
    return <span>Error: {FxOpsTransactions?.error.message}</span>;
  }

  return (
    <>
      <Container1>
        <Container className="input_container" width="20%">
          <Search
            placeholder={"Search reference no."}
            input={input}
            setInput={setInput}
            handleSearch={handleSearch}
          />
        </Container>
        <Filters
          source="transaction"
          currency={currency}
          setCurrency={setCurrency}
          statusValue={statusValue}
          value={value}
          handleSelectFromChange={handleSelectFromChange}
          handleSelectToChange={handleSelectToChange}
          onChange={onChange}
          onChangeStatus={onChangeStatus}
          selectedDateFrom={selectedDateFrom}
          selectedDateTo={selectedDateTo}
          setFilter
          handleCurrencyChange={handleCurrencyChange}
          refetch={handleSubmit}
          loading={clicked === "true" && FxOpsTransactions.isRefetching}
        />
      </Container1>
      <CustomTable
        theadBkColor="#F1F4F8"
        bottomGap="10px"
        tableHead={tableHead}
        tableBody={FxOpsTransactions?.data?.data?.data?.data}
        rowHovColor="#d2ccc626"
        theadColor="#000A62"
        tbodyColor="#141414"
        pageSize={10}
        meta={FxOpsTransactions?.data?.data?.data?.meta}
        paginator
        handleViewDetails={showDetails}
        handleViewDetailsCrypto={showDetailsCrypto}
        handlePageChange={handlePageChange}
      />

      <ModalFrame
        visible={visible}
        handleCancel={handleCancel}
        footer={null}
        pad="1rem"
        width="550px"
      >
        <CreateNewAccount type="details" />
      </ModalFrame>
      <ModalFrame
        visible={visibleCrypto}
        handleCancel={handleCancel}
        footer={null}
        pad="1rem"
      >
        <h2
          style={{
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "21px",
            marginBottom: "20px",
          }}
        >
          Account details
        </h2>
        <CryptoAccountDetails />
      </ModalFrame>
    </>
  );
};

export default Transactions;

const tableHead = [
  "",
  "currency",
  "date created",
  "AMOUNT",
  "label",
  "Type",
  "status",
  "",
];
