import FsLightbox from 'fslightbox-react';
import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { BackIcon } from '../../../../assets';
import ButtonWithIcon from '../../../../components/buttonWithIcon';
import Layout from '../../../../components/layouts';
import { Loader } from '../../../../components/loader';
import ModalFrame from '../../../../components/modal/ModalFrame';
import { useBankName } from '../../../../customHooks/useBankName';
import { useCopy } from '../../../../customHooks/useCopyToClipBoard';
import {
	getReceipt,
	getSingleTransactionById
} from '../../../../services/Transactions.service';
import {
	formattedTime,
	formattedTransactionDate
} from '../../../../utils/helpers';
import { ApproveTransactionSummary } from '../../approvals/approvalTransactionSummary/ApproveTransactionSummary';
import { Flex } from '../../../../components/box';
import {
	Container2,
	Container3,
	Styles,
	TransactionDetailsContainer
} from './styles';

const Index = () => {
	const [toggler, setToggler] = useState(false);
	const { transactionID } = useParams();
	const navigate = useNavigate();
	const [visible, setVisible] = useState(false);
	const [qrCode, setQrCode] = useState(false);

	const [statusAp, setStatusAp] = useState('approve');
	const [selectedIndex, setSelectedIndex] = useState([]);
	const { copyToClipboard, copySuccess } = useCopy();
	const { copySuccess: copySuccessRef, copyToClipboard: copyToClipboardRef } =
		useCopy();
	const { handleSelectBankName } = useBankName();
	const {
		copySuccess: copySuccessMerchant,
		copyToClipboard: copyToClipboardMerchant
	} = useCopy();
	const {
		copySuccess: copySuccessProvider,
		copyToClipboard: copyToClipboardProvider
	} = useCopy();

	const result = useQuery(
		'getSingleTransactionById',
		async () => await getSingleTransactionById(transactionID),
		{
			refetchOnWindowFocus: false
		}
	);

	// if (result.status === 'loading') {
	// 	return <Loader full />;
	// }

	if (result.status === 'error') {
		return <span>Error: {result.error}</span>;
	}
	const transactionData = result?.data?.data?.data;

	const handleDownload = (id) => {
		try {
			getReceipt(id).then((res) => {
				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(`download`, `invoice.${'pdf'}`);
				document.body.appendChild(link);
				link.click();
				// link.parentNode.removeChild(link);
			});
		} catch (error) {
			console.log(error.response);
		}
		console.log(id);
	};
	const status = transactionData?._status;

	const obj =
		transactionData?.type === 'WITHDRAWAL'
			? transactionData?.beneficiary !== null ||
				transactionData?.beneficiary !== undefined ||
				Object.keys(transactionData?.beneficiary)?.length !== 0
				? JSON.parse(transactionData?.beneficiary)
				: {}
			: {};

	const showModal = (e) => {
		setVisible(true);
		setStatusAp(e);
	};
	const handleqrCode = () => {
		setQrCode(true);
	};

	const handleOk = () => {
		setTimeout(() => {
			setVisible(false);
		}, 2000);
	};

	const handleCancel = () => {
		setVisible(false);
	};

	return (
		<Layout title={'Transaction'}>
			{result?.isFetching ? (
				<Loader />
			) : (
				<Styles>
					<Flex
						onClick={() => navigate(-1)}
						className='go_back'
						alignItems='center'
						justifyContent='flex-start'
					>
						<BackIcon />
						<span style={{ paddingLeft: '20px' }}>Go Back</span>
					</Flex>
					<Flex
						alignItems='center'
						justifyContent='flex-start'
					// bgColor='red'
					>
						<Container2
							status={status === 'approval' ? transactionData?.status : status}
						>
							<Flex justifyContent={'flex-start'}>
								<span>{transactionData?.currency}</span>
								<span>{transactionData?.amount?.toLocaleString()}</span>
								<span>
									{status === 'approval' ? transactionData?.status : status}
								</span>
							</Flex>

							{transactionData?.approval_required && (
								<Flex width='30%' gap='16px'>
									<ButtonWithIcon
										Styles
										text='Approve'
										bgColor='#E4F7E3'
										// <AcceptTransactionIcon color='#00B428' />
										icon={
											<svg
												width='20'
												height='20'
												viewBox='0 0 20 20'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M8.525 14.55L15.6 7.475L14.45 6.35L8.525 12.275L5.525 9.275L4.4 10.4L8.525 14.55ZM10 20C8.63333 20 7.34167 19.7375 6.125 19.2125C4.90833 18.6875 3.84583 17.9708 2.9375 17.0625C2.02917 16.1542 1.3125 15.0917 0.7875 13.875C0.2625 12.6583 0 11.3667 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.02917 3.825 2.9375 2.925C3.84583 2.025 4.90833 1.3125 6.125 0.7875C7.34167 0.2625 8.63333 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3667 19.7375 12.6583 19.2125 13.875C18.6875 15.0917 17.975 16.1542 17.075 17.0625C16.175 17.9708 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z'
													fill='#027200'
												/>
											</svg>
										}
										color='#027200'
										size='sm'
										borderRadius={'4px'}
										onClick={() => showModal('approve')}
									// disabled={
									// 	route === 'personal'
									// 		? checKPersonalComp
									// 		: checKBusinessComp
									// }
									// walletInfo?.data?.data?.data?.fiat_compliance_status
									// loading={approveComplianceMutation.isLoading}
									/>

									<ButtonWithIcon
										Styles
										text='Reject'
										color={'#C12300'}
										bgColor='#FCE9E3'
										size='sm'
										borderRadius={'4px'}
										icon={
											<svg
												width='20'
												height='20'
												viewBox='0 0 20 20'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M6.25 14.8L10 11.05L13.75 14.8L14.8 13.75L11.05 10L14.8 6.25L13.75 5.2L10 8.95L6.25 5.2L5.2 6.25L8.95 10L5.2 13.75L6.25 14.8ZM10 20C8.63333 20 7.34167 19.7375 6.125 19.2125C4.90833 18.6875 3.84583 17.9708 2.9375 17.0625C2.02917 16.1542 1.3125 15.0917 0.7875 13.875C0.2625 12.6583 0 11.3667 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.02917 3.825 2.9375 2.925C3.84583 2.025 4.90833 1.3125 6.125 0.7875C7.34167 0.2625 8.63333 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3667 19.7375 12.6583 19.2125 13.875C18.6875 15.0917 17.975 16.1542 17.075 17.0625C16.175 17.9708 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z'
													fill='#C12300'
												/>
											</svg>
										}
										onClick={() => showModal('reject')}

									// disabled={
									// 	route === 'personal'
									// 		? checKPersonalCompRej
									// 		: checKBusinessCompRej
									// }
									// loading={approveComplianceMutation.isFetching}
									/>
								</Flex>
							)}
							{transactionData?._withdrawal_id !== '' && (
								<a
									className='download'
									href={`/dashboard/transactions/withdrawals/${transactionData?._withdrawal_id}`}
								>
									View withdrawal transaction
								</a>
							)}
							{transactionData?._swap_id !== '' && (
								<a
									className='download'
									href={`/dashboard/transactions/swap/${transactionData?._swap_id}`}
								>
									View swap transaction
								</a>
							)}
						</Container2>
					</Flex>
					<Container3 gap={'10px'} gridCol={'repeat(3,1fr)'}>
						<div className='box'>
							<span>DATE</span>
							<div>
								<span>
									{formattedTransactionDate(transactionData?.created_at)}
									<small> {formattedTime(transactionData?.created_at)}</small>
								</span>
							</div>
						</div>
						<div>
							<span>TNX REFERENCE</span>
							<div>
								<span> {transactionData?.reference}</span>
								<button
									className='copy'
									onClick={() => copyToClipboard(transactionData?.reference)}
								>
									{copySuccess}
								</button>
							</div>
						</div>
						<div>
							<span> Transaction Type</span>
							<div
								style={{
									width: '60px'
								}}
							>
								<span>{transactionData?.type}</span>
							</div>
						</div>
						{transactionData?.type === 'WITHDRAWAL' && (
							<div>
								<span>Swap</span>
								<div
									style={{
										width: '60px'
									}}
								>
									<span>
										{transactionData?._swap_id !== '' ? 'True' : 'False'}
									</span>
								</div>
							</div>
						)}
						{transactionData?.type === 'SWAP' && (
							<div>
								<span>Withdrawal</span>
								<div
									style={{
										width: '60px'
									}}
								>
									<span>
										{transactionData?._withdrawal_id !== '' ? 'True' : 'False'}
									</span>
								</div>
							</div>
						)}
					</Container3>

					<TransactionDetailsContainer status={status}>
						<div className='title'>
							<h1>TRANSACTION DETAILS</h1>
						</div>
						<Flex className='details'>
							<span>Merchant Reference</span>
							<div>
								{transactionData?.merchant_reference !== null && (
									<button
										onClick={() =>
											copyToClipboardMerchant(
												transactionData?.merchant_reference
											)
										}
									>
										{copySuccessMerchant}
									</button>
								)}
								<span>{transactionData?.merchant_reference}</span>
							</div>
						</Flex>

						{transactionData?.type === 'WITHDRAWAL' && (
							<>
								<Flex className='details'>
									<span>Balance Before</span>
									<span>
										{`
										${transactionData?.status !== 'success'
												? transactionData?._from_balance_before === null
													? ''
													: transactionData?._from_balance_before
												: transactionData?.from_balance_before === null
													? ''
													: `${transactionData?.currency?.toLocaleString()}
													${transactionData?.from_balance_before?.toLocaleString()}`
											}

						`}
									</span>
								</Flex>
							</>
						)}
						{transactionData?.type === 'DEPOSIT' && (
							<>
								<Flex className='details'>
									<span>Balance Before</span>
									<span>
										{`
										${transactionData?.status !== 'success'
												? transactionData?._to_balance_before === null
													? ''
													: transactionData?._to_balance_before
												: transactionData?.to_balance_before === null
													? ''
													: `${transactionData?.currency?.toLocaleString()}
													${transactionData?.to_balance_before?.toLocaleString()}`
											}

						`}
									</span>
								</Flex>
							</>
						)}
						{transactionData?.type === 'WITHDRAWAL' && (
							<>
								<Flex className='details'>
									<span>Balance After</span>
									<span>
										{`
										${transactionData?.status !== 'success'
												? transactionData?._from_balance_after === null
													? ''
													: transactionData?._from_balance_after
												: transactionData?.from_balance_after === null
													? ''
													: `${transactionData?.currency?.toLocaleString()}
													${transactionData?.from_balance_after?.toLocaleString()}`
											}
										`}
									</span>
								</Flex>
							</>
						)}
						{transactionData?.type === 'DEPOSIT' && (
							<>
								<Flex className='details'>
									<span>Balance After</span>
									<span>
										{`
										${transactionData?.status !== 'success'
												? transactionData?._to_balance_after === null
													? ''
													: transactionData?._to_balance_after
												: transactionData?.to_balance_after === null
													? ''
													: `${transactionData?.currency?.toLocaleString()}
													${transactionData?.to_balance_after?.toLocaleString()}`
											}
										`}
									</span>
								</Flex>
							</>
						)}

						{transactionData?.type === 'SWAP' && (
							<>
								<Flex className='details'>
									<span>Swap Details</span>
									<span>
										{`${transactionData?.from_currency?.toLocaleString()} ${transactionData?.from_amount?.toLocaleString()}   <-> ${transactionData?.to_currency?.toLocaleString()}
                ${transactionData?.to_amount}
                `}
									</span>
								</Flex>
								<Flex className='details'>
									<span>Rate</span>
									<span>
										{transactionData?.from_amount < transactionData?.to_amount
											? `1 ${transactionData?.from_currency} = ${transactionData?.to_currency} ${transactionData?.trx_rate}`
											: `1 ${transactionData?.to_currency} = ${transactionData?.from_currency} ${transactionData?.trx_rate}`}
									</span>
								</Flex>
								<Flex className='details'>
									<span>Expires In</span>
									<Countdown
										date={
											transactionData.settlement_date === null
												? '1918-09-08T09:55:54.000Z'
												: transactionData?.settlement_date
										}
									/>
								</Flex>
								<Flex className='details'>
									<span>Amount Received</span>
									<span>
										{`${transactionData?.to_currency?.toLocaleString()} ${transactionData?.amount_settled?.toLocaleString()}`}
									</span>
								</Flex>
							</>
						)}
						<Flex className='details'>
							<span>Amount Charged</span>
							<span>
								{`${transactionData?.currency?.toLocaleString()} ${transactionData?.amount_charged === null
										? 0
										: transactionData?.type === 'SWAP'
											? transactionData?.to_amount?.toLocaleString()
											: transactionData?.amount_charged?.toLocaleString()
									}
                `}
							</span>
						</Flex>

						{transactionData?.type !== 'SWAP' && (
							<>
								<Flex className='details'>
									<span> Amount Settled</span>
									<span>
										{`${transactionData?.currency?.toLocaleString()} ${transactionData?.amount_settled === null
												? 0
												: transactionData?.amount_settled?.toLocaleString()
											}`}
									</span>
								</Flex>
							</>
						)}

						<Flex className='details'>
							<span>Status</span>
							<span>
								<div className='dot' />
								{status === 'approval' ? transactionData?.status : status}
							</span>
						</Flex>
						<Flex className='details'>
							<span>Channel</span>
							<span>{transactionData?.channel}</span>
						</Flex>
						<Flex className='details'>
							<span> Fees (Vat Inclusive) </span>
							<span>
								{transactionData?.fee_type !== null && (
									<>
										{transactionData?.fee_type === 'FLAT' &&
											transactionData?.to_currency === null
											? transactionData?.currency
											: transactionData?.to_currency}{' '}
									</>
								)}
								{transactionData?.fee?.toLocaleString()}
								{transactionData?.fee_type !== null && (
									<>{transactionData?.fee_type === 'PERCENTAGE' && '%'}</>
								)}
							</span>
						</Flex>
						<Flex className='details'>
							<span>Fee Type</span>
							<span>{transactionData?.fee_type}</span>
						</Flex>
						<Flex className='details'>
							<span>Fee Value</span>
							<span>
								{transactionData?.fee_type !== 'PERCENTAGE' && (
									<>
										{transactionData?.fee_type === 'FLAT' &&
											transactionData?.to_currency === null
											? transactionData?.currency
											: transactionData?.to_currency}{' '}
									</>
								)}
								{transactionData?.fee_value}
								{transactionData?.fee_type !== null && (
									<>{transactionData?.fee_type === 'PERCENTAGE' && '%'}</>
								)}
							</span>
						</Flex>
						<Flex className='details'>
							<span>Fee Bearer</span>
							<span>{transactionData?.fee_bearer}</span>
						</Flex>
						{transactionData?.type !== 'SWAP' && (
							<>
								<Flex className='details'>
									<span>Payment Method</span>
									<span>
										{transactionData?.payment_slug?.includes('-')
											? transactionData?.payment_slug
												.replace('-', ' ')
												.replace('-', ' ')
											: transactionData?.payment_slug}
									</span>
								</Flex>

								<Flex className='details'>
									<span>Provider</span>
									<span>{transactionData?.payment_provider}</span>
								</Flex>
								<Flex className='details'>
									<span>Provider Reference</span>
									<div>
										{transactionData?.provider_reference !== null && (
											<button
												onClick={() =>
													copyToClipboardProvider(
														transactionData?.provider_reference
													)
												}
											>
												{copySuccessProvider}
											</button>
										)}
										<span>{transactionData?.provider_reference}</span>
									</div>
								</Flex>
								<Flex className='details'>
									<span>Tracking Id</span>
									<span>{transactionData?.trace_id}</span>
								</Flex>
								<Flex className='details'>
									<span>Description</span>
									<span>{transactionData?.narration}</span>
								</Flex>
							</>
						)}

						<Flex
							className='details'
						// style={{
						// 	marginBottom: '30px'
						// }}
						>
							<span>Reason</span>
							<span>{transactionData?.reason}</span>
						</Flex>

						<Flex className='details'>
							<span>Approved By</span>
							<span>{transactionData?.approved_by}</span>
						</Flex>
						<Flex
							className='details'
							style={{
								marginBottom: '30px'
							}}
						>
							<span>Transaction Data</span>
							<span>
								{JSON.stringify(transactionData?.request_data, null, 2)}
							</span>
						</Flex>

						{transactionData?.reject_reason && (
							<Flex className='details'>
								<span>Reject Reason</span>
								<span>{transactionData?.reject_reason}</span>
							</Flex>
						)}
						{transactionData?.type !== 'SWAP' && (
							<>
								<section />
								<div className='title'>
									<h4>PAYMENT INFO</h4>
								</div>
								{transactionData?.type === 'WITHDRAWAL' && (
									<Flex className='details' alignItem='flex-start'>
										<span>Beneficiary Details</span>
										<Flex alignItem={'flex-end'} flexDir={'column'}>
											{obj && (
												<>
													<Flex
														alignItem='flex-end'
														width='fit-content'
														justifyContent='space-between'
													>
														<h4 style={{ marginRight: '10px' }}>
															{obj?.account_number
																? 'Account Number:'
																: 'Address:'}
														</h4>
														<h4>
															{obj?.account_number
																? obj?.account_number
																: obj?.address}
														</h4>
													</Flex>
													<Flex
														alignItem='flex-end'
														width='fit-content'
														justifyContent='space-between'
													>
														<h4 style={{ marginRight: '10px' }}>
															{obj?.account_name ? 'Account Name:' : 'Network:'}
														</h4>

														<h4>
															{obj?.account_name
																? obj?.account_name
																: obj?.network}
														</h4>
													</Flex>
													{obj?.name_of_bank && (
														<Flex
															alignItem='flex-end'
															width='fit-content'
															justifyContent='space-between'
														>
															<h4 style={{ marginRight: '10px' }}>
																Bank Name:
															</h4>
															<h4>{obj?.name_of_bank}</h4>
														</Flex>
													)}
												</>
											)}
										</Flex>
									</Flex>
								)}
								{transactionData?.type === 'DEPOSIT' && (
									<Flex className='details'>
										<span>payment proof</span>
										<div>
											{transactionData?.payment_proof !== null && (
												<>
													<p
														className='lightBox'
														onClick={() => setToggler(!toggler)}
													>
														View deposit proof
													</p>
													<FsLightbox
														toggler={toggler}
														sources={[transactionData?.payment_proof]}
														type='image'
													/>
												</>
											)}
										</div>
									</Flex>
								)}
							</>
						)}
					</TransactionDetailsContainer>
				</Styles>
			)}
			<ModalFrame
				visible={visible}
				handleOk={handleOk}
				handleCancel={handleCancel}
				footer={null}
			>
				<ApproveTransactionSummary
					id={transactionData?.id}
					handleQrScreen={handleqrCode}
					status={statusAp}
					setVisible={setVisible}
				/>
			</ModalFrame>
		</Layout>
	);
};

export default Index;
