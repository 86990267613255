import apiClient from "./axios";

const EndPoints = {
  addStaff: `/admin/settings/staffs`,
  getStaff(search) {
    return `/admin/settings/staffs?search=${search}`;
  },
  getStaffs(ref, filterData) {
    let query = `/admin/settings/staffs?order=DESC&take=10`;

    if (filterData?.page) {
      query += `&page=${filterData?.page}`;
    }
    // if (ref) {
    // 	query += `&ref=${ref}`
    // }
    return query;
  },
  getInterconnected(filterData) {
    let query = `/admin/merchants/interconnected?order=DESC&take=10`;

    if (filterData?.page) {
      query += `&page=${filterData?.page}`;
    }
    // if (ref) {
    // 	query += `&ref=${ref}`;
    // }
    return query;
  },
  // getTransactionsDeposit(ref, filterData) {
  // 	let query = `/admin/transactions?type=deposit&
  // 	order=DESC&take=10&approvals=false`;

  // 	if (filterData.page) {
  // 		query += `&page=${filterData.page}`;
  // 	}
  // 	if (filterData.status) {
  // 		query += `&status=${filterData.status}`;
  // 	}
  // 	if (filterData.from) {
  // 		query += `&from=${filterData.from}`;
  // 	}
  // 	if (filterData.to) {
  // 		query += `&to=${filterData.to}`;
  // 	}
  // 	if (filterData.currency) {
  // 		query += `&currency=${filterData.currency}`;
  // 	}
  // 	if (ref) {
  // 		query += `&ref=${ref}`;
  // 	}

  // 	return query;
  // },
  getUserprofile: `/admin/settings/profile`,
  changePassword: `/admin/settings/password`,
  editUserProfile: `/admin/settings/profile`,
  editUserPhoto: `/admin/settings/profile/photo`,
  getsettlementTime: `/admin/settings/settlement-time`,
  addsettlementTime: `/admin/settings/settlement-time`,
  paymentMethodDeposit: `/admin/settings/deposit-methods`,
  addpaymentMethodDeposit: `/admin/settings/deposit-methods`,
  paymentMethodWithdrawal: `/admin/settings/withdrawal-methods`,
  addPaymentMethodWithdrawal: `/admin/settings/withdrawal-methods`,
  getApprovers: `/admin/approvers`,
  addApprover: `/admin/approvers`,
  getRateSource: `/admin/rate-source`,
  addRateSource: `/admin/rate-source`,
  getAllCurrencies: `/admin/settings/currencies`,
  addCurrency: `/admin/settings/currencies`,
  disableCurrency: `/admin/settings/currencies/disable`,
  enableCurrency: `/admin/settings/currencies/enable`,
  disableAllCurrencies: `/admin/settings/currencies/disable/all`,
  setApproverNumber: `/admin/min-approvers`,
  getSettingsConfig: `/admin/settings/config`,
  // settings/config

  getSettings: `/admin/settings`,
  refreshRate: `/admin/refresh-rate`,
  getCurrencyPair: `/admin/settings/pairs`,
  addCurrencyPair: `/admin/settings/pairs`,
  getRoles: `/admin/role`,
  getSessions: `/admin/sessions`,
  toggleWalletCreation: `/admin/validate-wallet-compliance`,
  toggleBvnCreation: `/admin/validate-wallet-bvn`,
  walletLimit: `/admin/wallet-limit`,
  dynamicAccountLimit: `/admin/dynamic-accpunt-expire`,

  editCurrency(id) {
    return `/admin/settings/currencies/${id}`;
  },

  editCurrencyPair(id) {
    return `/admin/settings/pairs/${id}`;
  },

  deleteCurrencyPair(id) {
    return `/admin/settings/pairs/${id}`;
  },

  getSinglepaymentMethodDeposit(id) {
    return `/admin/settings/deposit-methods?merchantId=${id}`;
  },

  statusDeposit(id) {
    return `/admin/settings/deposit-methods/${id}/status`;
  },

  statusWithdrawal(id) {
    return `/admin/settings/withdrawal-methods/${id}/status`;
  },

  getSinglepaymentMethodWithdrawal(id) {
    return `/admin/settings/withdrawal-methods?merchantId=${id}`;
  },

  revokeApprover(id) {
    return `/admin/approvers/${id}/revoke`;
  },

  setRateSource(id) {
    return `/admin/rate-source/${id}/default`;
  },

  editPaymentMethodDeposit(id) {
    return `/admin/settings/deposit-methods/${id}`;
  },

  editPaymentMethodWithdrawal(id) {
    return `/admin/settings/withdrawal-methods/${id}`;
  },

  deletePaymentMethodDeposit(id) {
    return `/admin/settings/deposit-methods/${id}`;
  },

  deletePaymentMethodWithdrawal(id) {
    return `/admin/settings/withdrawal-methods/${id}`;
  },

  duplicatePaymentMethodWithdrawal(id) {
    return `/admin/settings/withdrawal-methods/${id}/duplicate`;
  },

  duplicatePaymentMethodDeposit(id) {
    return `/admin/settings/deposit-methods/${id}/duplicate`;
  },

  disablePaymentMethodDeposit(id) {
    return `/admin/settings/deposit-methods/${id}/status`;
  },

  disablePaymentMethodWithdrawal(id) {
    return `/admin/settings/withdrawal-methods/${id}/status`;
  },

  merchantPaymentMethod(id) {
    return `/admin/payment/methods?merchantId=${id}`;
  },

  deleteSettlementTime(id) {
    return `/admin/settings/settlement-time/${id}`;
  },

  editSettlementTime(id) {
    return `/admin/settings/settlement-time/${id}`;
  },

  editStaff(id) {
    return `/admin/settings/staffs/${id}`;
  },

  reset2fa(id) {
    return `/admin/settings/staffs/${id}/reset2fa`;
  },

  suspendStaff(id) {
    return `/admin/settings/staffs/${id}/suspend`;
  },
  unsuspendStaff(id) {
    return `/admin/settings/staffs/${id}/unsuspend`;
  },

  deleteStaff(id) {
    return `/admin/settings/staffs/${id}`;
  },

  getActivities(filterData) {
    let query = `/admin/activities?order=DESC&take=10`;

    if (filterData.page) {
      query += `&page=${filterData.page}`;
    }

    return query;
  },
  terminateSession(id) {
    return `/admin/sessions/${id}`;
  },
  appConfig: `/admin/settings/config`,
  crossFee: `/admin/fee/cross`,
  addCrossFee(id) {
    if (id !== undefined) {
      return `/admin/fee/cross?merchantId=${id}`;
    } else {
      return `/admin/fee/cross`;
    }
  },
  editCrossFee(id) {
    return `/admin/fee/cross/${id}`;
  },
  enableCrossFee(id) {
    return `/admin/fee/cross/${id}/enable`;
  },
  disableCrossFee(id) {
    return `/admin/fee/cross/${id}/disable`;
  },
  add(id) {
    return `/admin/merchants/${id}/interconnected/add`;
  },
  switchInterconnected(id) {
    return `/admin/merchants/${id}/interconnected/switch`;
  },
  delFee(id) {
    return `/admin/fee/cross/${id}`;
  },
};

export const getUserprofile = async () => {
  const res = await apiClient.get(EndPoints.getUserprofile);
  return res;
};

export const editUserProfile = async (payload) => {
  const res = await apiClient.post(EndPoints.editUserProfile, payload);
  return res;
};

export const editUserPhoto = async (payload) => {
  const res = await apiClient.post(EndPoints.editUserPhoto, payload);
  return res;
};

export const getRateSource = async () => {
  const res = await apiClient.get(EndPoints.getRateSource);
  return res;
};

export const addRateSource = async (payload) => {
  const res = await apiClient.post(EndPoints.addRateSource, payload);
  return res;
};

export const setRateSource = async (id) => {
  const res = await apiClient.patch(EndPoints.setRateSource(id));
  return res;
};

export const getSinglepaymentMethodWithdrawal = async (id) => {
  const res = await apiClient.get(
    EndPoints.getSinglepaymentMethodWithdrawal(id)
  );
  return res;
};

export const statusWithdrawal = async (id, payload) => {
  const res = await apiClient.patch(EndPoints.statusWithdrawal(id), payload);
  return res;
};

export const statusDeposit = async (id, payload) => {
  const res = await apiClient.patch(EndPoints.statusDeposit(id), payload);
  return res;
};

export const getSinglepaymentMethodDeposit = async (id) => {
  const res = await apiClient.get(EndPoints.getSinglepaymentMethodDeposit(id));
  return res;
};

export const getAllCurrencies = async (id) => {
  const res = await apiClient.get(EndPoints.getAllCurrencies);
  return res;
};
export const toggleWalletCreation = async (data) => {
  const res = await apiClient.post(EndPoints.toggleWalletCreation, data);
  return res;
};
export const toggleWalletCreationBvn = async (data) => {
  const res = await apiClient.post(EndPoints.toggleBvnCreation, data);
  return res;
};

export const addCurrency = async (payload) => {
  const res = await apiClient.post(EndPoints.addCurrency, payload);
  return res;
};

export const editCurrency = async (id, payload) => {
  const res = await apiClient.patch(EndPoints.editCurrency(id), payload);
  return res;
};

export const disableCurrency = async (payload) => {
  const res = await apiClient.patch(EndPoints.disableCurrency, payload);
  return res;
};

export const enableCurrency = async (payload) => {
  const res = await apiClient.patch(EndPoints.enableCurrency, payload);
  return res;
};

export const disableAllCurrencies = async () => {
  const res = await apiClient.patch(EndPoints.disableAllCurrencies);
  return res;
};

export const getSettlementTime = async () => {
  const res = await apiClient.get(EndPoints.getsettlementTime);
  return res;
};

export const addsettlementTime = async (payload) => {
  const res = await apiClient.post(EndPoints.getsettlementTime, payload);
  return res;
};

export const editsettlementTime = async (id, payload) => {
  const res = await apiClient.patch(EndPoints.editSettlementTime(id), payload);
  return res;
};

export const deleteSettlementTime = async (id) => {
  const res = await apiClient.delete(EndPoints.deleteSettlementTime(id));
  return res;
};

export const getpaymentMethodWithdrawal = async () => {
  const res = await apiClient.get(EndPoints.paymentMethodWithdrawal);
  return res;
};

export const getpaymentMethodDeposit = async () => {
  const res = await apiClient.get(EndPoints.paymentMethodDeposit);
  return res;
};

export const addPaymentMethodWithdrawal = async (payload) => {
  const res = await apiClient.post(
    EndPoints.addPaymentMethodWithdrawal,
    payload
  );
  return res;
};

export const addpaymentMethodDeposit = async (payload) => {
  const res = await apiClient.post(EndPoints.addpaymentMethodDeposit, payload);
  return res;
};

export const editpaymentMethodDeposit = async (id, payload) => {
  const res = await apiClient.patch(
    EndPoints.editPaymentMethodDeposit(id),
    payload
  );
  return res;
};

export const editpaymentMethodWithdrawal = async (id, payload) => {
  const res = await apiClient.patch(
    EndPoints.editPaymentMethodWithdrawal(id),
    payload
  );
  return res;
};

export const deletePaymentMethodDeposit = async (id) => {
  const res = await apiClient.delete(EndPoints.deletePaymentMethodDeposit(id));
  return res;
};

export const deletePaymentMethodWithdrawal = async (id) => {
  const res = await apiClient.delete(
    EndPoints.deletePaymentMethodWithdrawal(id)
  );
  return res;
};

export const duplicatePaymentMethodWithdrawal = async (id) => {
  const res = await apiClient.get(
    EndPoints.duplicatePaymentMethodWithdrawal(id)
  );
  return res;
};

export const duplicatePaymentMethodDeposit = async (id) => {
  const res = await apiClient.get(EndPoints.duplicatePaymentMethodDeposit(id));
  return res;
};

export const disablePaymentMethodDeposit = async (id, status) => {
  const res = await apiClient.patch(
    EndPoints.disablePaymentMethodDeposit(id),
    status
  );
  return res;
};

export const disablePaymentMethodWithdrawal = async (id, status) => {
  const res = await apiClient.patch(
    EndPoints.disablePaymentMethodWithdrawal(id),
    status
  );
  return res;
};

export const merchantPaymentMethod = async (id) => {
  const res = await apiClient.get(EndPoints.merchantPaymentMethod(id));
  return res;
};

export const getApprovers = async () => {
  const res = await apiClient.get(EndPoints.getApprovers);
  return res;
};

export const getSettingsConfig = async () => {
  const res = await apiClient.get(EndPoints.getSettingsConfig);
  return res;
};
export const getSettings = async () => {
  const res = await apiClient.get(EndPoints.getSettings);
  return res;
};
export const updateGeneralWalletLimit = async (payload) => {
  const res = await apiClient.post(EndPoints.walletLimit, payload);
  return res;
};
export const updateDynamicAccountLimit = async (payload) => {
  const res = await apiClient.post(EndPoints.dynamicAccountLimit, payload);
  return res;
};

export const addApprover = async (payload) => {
  const res = await apiClient.post(EndPoints.addApprover, payload);
  return res;
};

export const setApproverNumber = async (payload) => {
  const res = await apiClient.post(EndPoints.setApproverNumber, payload);
  return res;
};

export const refreshRate = async (payload) => {
  const res = await apiClient.post(EndPoints.refreshRate, payload);
  return res;
};

export const revokeApprover = async (id) => {
  const res = await apiClient.delete(EndPoints.revokeApprover(id));
  return res;
};

export const getStaffs = async (ref, filterData) => {
  const res = await apiClient.get(EndPoints.getStaffs(ref, filterData));
  return res;
};
export const getStaff = async (search) => {
  const res = await apiClient.get(EndPoints.getStaff(search));
  return res;
};

export const getInterconnected = async (filterData) => {
  const res = await apiClient.get(EndPoints.getInterconnected(filterData));
  return res;
};
export const getRoles = async () => {
  const res = await apiClient.get(EndPoints.getRoles);
  return res;
};

export const addStaff = async (payload) => {
  const res = await apiClient.post(EndPoints.addStaff, payload);
  return res;
};
export const appConfig = async (payload) => {
  const res = await apiClient.post(EndPoints.appConfig, payload);
  return res;
};
export const add = async (id, payload) => {
  const res = await apiClient.post(EndPoints.add(id), payload);
  return res;
};
export const switchInterconnected = async (id, payload) => {
  console.log(id, payload);
  const res = await apiClient.post(EndPoints.switchInterconnected(id), payload);
  return res;
};

export const editStaff = async (payload, id) => {
  const res = await apiClient.patch(EndPoints.editStaff(id), payload);
  return res;
};

export const reset2fa = async (id, payload) => {
  const res = await apiClient.patch(EndPoints.reset2fa(id), payload);
  return res;
};

export const suspendStaff = async (id, payload) => {
  const res = await apiClient.patch(EndPoints.suspendStaff(id), payload);
  return res;
};
export const unsuspendStaff = async (id, payload) => {
  const res = await apiClient.patch(EndPoints.unsuspendStaff(id), payload);
  return res;
};
export const deleteStaff = async (id) => {
  const res = await apiClient.delete(EndPoints.deleteStaff(id));
  return res;
};

export const changePassword = async (payload) => {
  const res = await apiClient.post(EndPoints.changePassword, payload);
  return res;
};

export const getCurrencyPair = async () => {
  const res = await apiClient.get(EndPoints.getCurrencyPair);
  return res;
};

export const getSessions = async () => {
  const res = await apiClient.get(EndPoints.getSessions);
  return res;
};

export const addCurrencyPair = async (payload) => {
  const res = await apiClient.post(EndPoints.addCurrencyPair, payload);
  return res;
};

export const editCurrencyPair = async (id, payload) => {
  const res = await apiClient.patch(EndPoints.editCurrencyPair(id), payload);
  return res;
};

export const deleteCurrencyPair = async (id) => {
  const res = await apiClient.delete(EndPoints.deleteCurrencyPair(id));
  return res;
};

export const getActivities = async (filterData) => {
  const res = await apiClient.get(EndPoints.getActivities(filterData));
  return res;
};

export const terminateSession = async (id) => {
  const res = await apiClient.delete(EndPoints.terminateSession(id));
  return res;
};

export const getCrossFee = async () => {
  const res = await apiClient.get(EndPoints.crossFee);
  return res;
};

export const addCrossFee = async (id, payload) => {
  const res = await apiClient.post(EndPoints.addCrossFee(id), payload);
  return res;
};
export const editCrossFee = async (id, payload) => {
  const res = await apiClient.patch(EndPoints.editCrossFee(id), payload);
  return res;
};
export const disableCrossFee = async (id) => {
  const res = await apiClient.patch(EndPoints.disableCrossFee(id));
  return res;
};
export const enableCrossFee = async (id) => {
  const res = await apiClient.patch(EndPoints.enableCrossFee(id));
  return res;
};
export const deleteCrossFee = async (id) => {
  const res = await apiClient.delete(EndPoints.delFee(id));
  return res;
};
