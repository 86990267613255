import React from "react";
import { Flex } from "../../../../components/box";
import { Styles, Sidebar, TableContainer } from "./styles";
import { RightArrowIcon } from "../../../../assets";
import CustomTable from "./table";
import {
  OverviewPendingKYCTableHead,
} from "../../../../utils/constants";
import { Link } from "react-router-dom";
import { Skeleton } from "antd";
import { Loader } from "../../../../components/loader/index";

const Index = ({
  pendingCompliance,
  complianceSummaryRejected,
  compliancePending,
  complianceApproved,
  loading,
  loadingPendingRecentCompliance,
}) => {
  return (
    <Styles>
      <Flex className="header-container" justifyContent={"flex-start"}>
        <h3>Compliance</h3>
      </Flex>
      <Flex justifyContent="space-between">
        <Sidebar
          flexDir="column"
          justifyContent="space-between"
          alignItem="flex-start"
          flexB="20%"
        >
          <h2>Overview</h2>
          <div>
            <p>Pending KYC</p>
            {loading ? (
              <Skeleton paragraph={{ rows: 0 }} />
            ) : (
              <span>{compliancePending?.result}</span>
            )}
          </div>
          <div>
            <p>Approved KYC</p>
            {loading ? (
              <Skeleton paragraph={{ rows: 0 }} />
            ) : (
              <span>{complianceApproved?.result}</span>
            )}
          </div>
          <div>
            <p>Canceled KYC</p>
            {loading ? (
              <Skeleton paragraph={{ rows: 0 }} />
            ) : (
              <span>{complianceSummaryRejected?.result}</span>
            )}
          </div>
          <Flex justifyContent="space-between" alignItem="center">
            <p style={{ margin: "0" }}>VIEW FULL REPORT</p>
            <Link to={"/dashboard/compliance"}>
              <RightArrowIcon />
            </Link>
          </Flex>
        </Sidebar>
        <TableContainer flexB="78%" flexDir="column" alignItem="flex-start">
          <h3 className="kyc">
            Pending KYC <span>(Showing recent)</span>
          </h3>
          {loadingPendingRecentCompliance ? (
            <Flex>
              <Loader />
            </Flex>
          ) : (
            <CustomTable
              theadBkColor="#fff"
              bottomGap="10px"
              tableHead={OverviewPendingKYCTableHead}
              tableBody={pendingCompliance?.data}
              rowHovColor="#d2ccc626"
              theadColor="#8e8e8e"
              tbodyColor="#141414"
              pageSize={6}
              // paginator
              type="home"
            />
          )}
        </TableContainer>
      </Flex>
    </Styles>
  );
};

export default Index;
