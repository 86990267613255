import React from "react";
import { Styles, Card } from "./styles";
import { Flex, Grid } from "../../../../components/box";
import { Skeleton } from "antd";
import Button from "../../../../components/button";
import ModalFrame from "../../../../components/modal/ModalFrame";
import DownloadReport from "../../../../components/downloadReport/DownloadReport";
import { useState } from "react";
import { BankDetails, CryptoDetails, Fund } from "../fundBalance";
import FxOpsForm from "../form";
import { useQuery } from "react-query";
import {
  fxOpsSummaryBalance,
  fxOpsSummaryDeposit,
  fxOpsSummaryPAYOUT,
  getDefaultAddressDetails,
  getDefaultbankDetails,
  getFxopsReportType,
} from "../../../../services/fxOps.services";
import { generateID } from "../../../../utils/generateID";

const Index = () => {
  const [visible, setVisible] = useState(false);
  const [startDownload, setStartDownload] = useState(false);
  const [visibleFund, setVisibleFund] = useState(false);
  const [visibleBank, setVisibleBank] = useState(false);
  const [visibleCrypto, setVisibleCrypto] = useState(false);
  const [visibleCryptoDetails, setVisibleCryptoDetails] = useState(false);
  const [currency, setCurrency] = useState(null);

  const [fileType, setFileType] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [transactionType, setTransactionType] = useState("");

  const balance = useQuery(
    "fxOpsBalance",
    async () => await fxOpsSummaryBalance()
  );

  const payout = useQuery(
    "fxOpsPayout",
    async () => await fxOpsSummaryPAYOUT()
  );

  const deposit = useQuery(
    "fxOpsDeposit",
    async () => await fxOpsSummaryDeposit()
  );

  const accountDetails = useQuery(
    "getDefaultbankDetails",
    async () => await getDefaultbankDetails()
  );

  const { data, refetch } = useQuery(
    "getDefaultAddressDetails",
    async () => await getDefaultAddressDetails(currency),
    {
      enabled: false,
      onSuccess: () => {
        showCryptoDetails();
      },
    }
  );

  const handleSelectCurrency = (value) => {
    setCurrency(value?.currency);
    setTimeout(() => refetch());
  };

  const showReport = () => {
    setVisible(true);
  };

  const showFund = () => {
    setVisibleBank(false);
    setVisibleCrypto(false);
    setVisibleCryptoDetails(false);
    setVisibleFund(true);
  };

  const showBank = () => {
    setVisibleFund(false);
    setVisibleBank(true);
  };
  const showCrypto = () => {
    setVisibleFund(false);
    setVisibleCrypto(true);
  };

  const showCryptoDetails = () => {
    setVisibleCrypto(false);
    setVisibleCryptoDetails(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setVisibleFund(false);
    setVisibleBank(false);
    setVisibleCrypto(false);
    setVisibleCryptoDetails(false);
  };

  const handleOk = () => {
    setStartDownload(true);
    if (transactionType === "PAYOUT") {
      getFxopsReportType(fromDate, toDate)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(`download`, `report.${"csv"}`);
          document.body.appendChild(link);
          link.click();
          setVisible(false);
          setStartDownload(false);
        })
        .catch((error) => {
          setVisible(false);
          setStartDownload(false);

          if (error.response) {
          } else if (error.request) {
          } else {
          }
        });
    }
    if (transactionType === "DEPOSIT") {
      getFxopsReportType(fromDate, toDate, transactionType)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(`download`, `report.${"csv"}`);
          document.body.appendChild(link);
          link.click();
          setVisible(false);
          setStartDownload(false);
        })
        .catch((error) => {
          setVisible(false);
          setStartDownload(false);

          if (error.response) {
          } else if (error.request) {
          } else {
          }
        });
    }
    setTimeout(() => {
      setVisible(false);
    }, 500);
  };
  return (
    <>
      <Styles>
        <Grid
          justifyContent="space-between"
          gap="10px"
          gridCol="repeat(auto-fit, minmax(200px, 1fr))"
          bgColor="#fff"
          pad="30px 20px"
        >
          <Card justifyContent="center" flexDir="column">
            <Flex
              className="container"
              alignItem={"flex-start"}
              justifyContent={"flex-start"}
            >
              <span>BALANCES</span>
            </Flex>
            <Flex
              className="viewall"
              alignItem={"flex-start"}
              flexDir={"column"}
            >
              {balance.isLoading ? (
                <Skeleton paragraph={{ rows: 0 }} />
              ) : (
                <Flex width="100%" gap="10px" justifyContent="flex-start">
                  {balance?.data?.data?.data?.map((item, i) => (
                    <span key={i.toString()}>{`${
                      item.currency
                    } ${item?.amount.toLocaleString()},`}</span>
                  ))}
                </Flex>
              )}
            </Flex>
            <Button
              Styles
              text="Fund Balance"
              color={"#1A2CCE"}
              type="submit"
              onClick={showFund}
              key="submit"
              form="editMerchant"
              style={{
                padding: "0",
                marginRight: "auto",
              }}
            />
          </Card>
          <Card
            justifyContent="center"
            flexDir="row"
            style={{ borderRight: "none" }}
          >
            <Flex justifyContent="center" flexDir="column">
              <Flex
                className="container"
                alignItem={"flex-start"}
                justifyContent={"flex-start"}
              >
                <span>PAYOUTS</span>
              </Flex>
              <Flex
                className="viewall"
                alignItem={"flex-start"}
                flexDir={"column"}
              >
                {payout.isLoading ? (
                  <Skeleton paragraph={{ rows: 0 }} />
                ) : (
                  <span>{`$ ${payout?.data?.data?.data?.result
                    .toFixed(2)
                    .toLocaleString()}`}</span>
                )}
              </Flex>
              <Button
                Styles
                text="Export Report"
                color={"#1A2CCE"}
                type="submit"
                key="submit"
                form="editMerchant"
                style={{
                  padding: "0",
                  marginRight: "auto",
                  textDecoration: "underline",
                }}
                onClick={showReport}
              />
            </Flex>
            <Flex justifyContent="center" flexDir="column">
              <Flex
                className="container"
                alignItem={"flex-start"}
                justifyContent={"flex-start"}
              >
                <span>DEPOSIT</span>
              </Flex>
              <Flex
                className="viewall"
                alignItem={"flex-start"}
                flexDir={"column"}
              >
                {deposit.isLoading ? (
                  <Skeleton paragraph={{ rows: 0 }} />
                ) : (
                  <span>{`$ ${deposit?.data?.data?.data.result
                    .toFixed(2)
                    .toLocaleString()}`}</span>
                )}
              </Flex>
              <Button
                Styles
                text="Export Report"
                color={"#1A2CCE"}
                type="submit"
                key="submit"
                form="editMerchant"
                style={{
                  padding: "0",
                  marginRight: "auto",
                  textDecoration: "underline",
                }}
                onClick={showReport}
              />
            </Flex>
          </Card>
        </Grid>
      </Styles>
      <ModalFrame
        visible={visible}
        handleCancel={handleCancel}
        footer={null}
        pad="1rem"
      >
        <h2
          style={{
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "21px",
            marginBottom: "20px",
          }}
        >
          Account details
        </h2>
        <DownloadReport
          type={"fxOps"}
          setFileType={setFileType}
          handleOk={handleOk}
          setFromDate={setFromDate}
          setToDate={setToDate}
          setTransactionType={setTransactionType}
          isLoading={startDownload}
        />
      </ModalFrame>
      <ModalFrame
        visible={visibleFund}
        handleCancel={handleCancel}
        footer={null}
        pad="1rem"
        width={"410px"}
      >
        <Fund handleBank={showBank} handleCrypto={showCrypto} />
      </ModalFrame>
      <ModalFrame
        visible={visibleBank}
        handleCancel={handleCancel}
        footer={null}
        pad="1rem"
      >
        <BankDetails
          accountDetails={accountDetails?.data?.data?.data?.data}
          handleFund={showFund}
          handleCancel={handleCancel}
        />
      </ModalFrame>
      <ModalFrame
        visible={visibleCrypto}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            color={"#404040"}
            bgColor={"#FFFFFF"}
            size="md"
            type="button"
            border={"0.7px solid #BFBFBF"}
            key="back"
            onClick={handleCancel}
            marginRight={5}
            borderRadius="5px"
          />,
          <Button
            Styles
            text="Confirm"
            bgColor={"#1A2CCE"}
            color={"#FFFFFF"}
            size="md"
            type="submit"
            key="submit"
            form="editMerchant"
            style={{ padding: "0.85rem 50px" }}
            borderRadius="5px"
          />,
        ]}
        pad="1rem"
      >
        <FxOpsForm type="fund" handleSubmit={handleSelectCurrency} />
      </ModalFrame>
      <ModalFrame
        visible={visibleCryptoDetails}
        handleCancel={handleCancel}
        footer={null}
        pad="1rem"
      >
        <h2
          style={{
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "21px",
            marginBottom: "20px",
          }}
        >
          Account details
        </h2>
        <CryptoDetails
          key={generateID(12)}
          handleFund={showFund}
          handleCancel={handleCancel}
          addressDetails={data?.data?.data}
        />
      </ModalFrame>
    </>
  );
};

export default Index;
