import { Form, Input } from "antd";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { SettingsProfileIcon } from "../../../../assets";
import { Flex } from "../../../../components/box";
import Button from "../../../../components/button";
import { Loader } from "../../../../components/loader";
import {
  editUserPhoto,
  editUserProfile,
  getUserprofile,
} from "../../../../services/Settings.service";
import { ToastMessage, type } from "../../../../utils/toastr";
import { FormContainer, InputFlexBox, MainContainer } from "./styles";

const Index = () => {
  const queryClient = useQueryClient();

  const [form] = Form.useForm();
  const ref = React.useRef(null);

  const result = useQuery("getUserprofile", async () => await getUserprofile());

  const mutation = useMutation(
    async (data) => {
      await editUserPhoto(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getUserprofile"]);
        ToastMessage(type.SUCCESS, "profile image successfully changed");
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const editProfileMutation = useMutation(
    async (data) => {
      await editUserProfile(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getUserprofile"]);
        ToastMessage(type.SUCCESS, "profile successfully updated");
      },
      onError: (error) => {
        ToastMessage(type.ERROR, error.response.data.message);
      },
    }
  );

  const handleOpenFileUpload = (e) => {
    e.preventDefault();
    ref.current.click();
  };

  const handleChange = (e) => {
    handleSubmitImage(e.target.files[0]);
  };

  const handleSubmitImage = async (data) => {
    try {
      let formData = new FormData();
      formData.append("file", data);
      await mutation.mutate(formData);
    } catch (error) {
      ToastMessage(type.ERROR, error.response.data.message);
    }
  };

  const handleFormSubmit = async (values) => {
    try {
      const { email, full_name, phone } = values;
      const payload = { email, full_name, phone, role_id: profile?.role?.id };
      await editProfileMutation.mutate(payload);
    } catch (error) {
      ToastMessage(type.ERROR, error.response.data.message);
    }
  };

  if (result.status === "loading") {
    return <Loader />;
  }

  if (result.status === "error") {
    return <span>Error: {result.error.message}</span>;
  }

  const profile = result?.data?.data?.data;

  return (
    <>
      <MainContainer>
        <FormContainer>
          <InputFlexBox>
            <Flex
              justifyContent="flex-start"
              alignItem="center"
              className="icon-box"
            >
              {profile?.avatar !== undefined || null ? (
                <div className="avatar">
                  <img
                    alt=""
                    src={profile.avatar === null ? "" : profile.avatar}
                  />
                </div>
              ) : (
                <SettingsProfileIcon />
              )}

              <input
                text={"Change profile picture"}
                color={"#1A2CCE"}
                size="md"
                type="file"
                ref={ref}
                onChange={handleChange}
              />
              <span onClick={handleOpenFileUpload} href="#">
                change profile picture
              </span>
            </Flex>
          </InputFlexBox>
          <InputFlexBox>
            <Form
              layout={"vertical"}
              form={form}
              initialValues={{
                layout: "vertical",
                size: "large",
              }}
              size="large"
              id="profile"
              onFinish={handleFormSubmit}
            >
              <Flex className="holder">
                <Flex className="box">
                  <Form.Item
                    label="Full Name"
                    name="full_name"
                    initialValue={profile?.full_name}
                  >
                    <Input placeholder="Christian Jumbo" type="text" />
                  </Form.Item>
                  <Form.Item
                    label="phone"
                    name="phone"
                    initialValue={profile?.phone}
                  >
                    <Input placeholder="813 212 0179" type="tel" />
                  </Form.Item>
                </Flex>
                <Flex className="box1">
                  <Form.Item
                    label="Email address"
                    name="email"
                    initialValue={profile?.email}
                  >
                    <Input
                      placeholder="chris.jumbo@trytreasura.com"
                      type="email"
                      disabled
                      value={profile?.email}
                    />
                  </Form.Item>
                  <Form.Item
                    initialValue={profile?.role?.name}
                    label="Role"
                    name="role_id"
                  >
                    <Input placeholder="Admin" type="text" disabled />
                  </Form.Item>
                </Flex>
              </Flex>
              <Button
                Styles
                text={"Save changes"}
                bgColor={"#1A2CCE"}
                color={"#FFFFFF"}
                size="md"
                type="submit"
                border={"0.7px solid #BFBFBF"}
                boxShadow={"0px 2px 10px rgba(0, 0, 0, 0.08)"}
                style={{ marginTop: "13px" }}
                borderRadius={"5px"}
                loading={editProfileMutation.isLoading}
              />
            </Form>
          </InputFlexBox>
        </FormContainer>
      </MainContainer>
    </>
  );
};

export default Index;
