import 'antd/dist/antd.min.css';
import React from 'react';
import { createRoot } from "react-dom/client";
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routes';
import './styles/index.css';
import './styles/toastr.css';

createRoot(document.getElementById('root')).render(
    <Routes />,
);