import React from 'react';
import { BsFillPatchCheckFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { CopyIcon } from '../../../../../assets/index';
import { Flex, Grid } from '../../../../../components/box';
import { useCopy } from '../../../../../customHooks/useCopyToClipBoard';
import { Card, Styles } from './styles';

const Index = ({ walletInfo, props }) => {
	const { copyToClipboard, copySuccess } = useCopy();

	// const walletInfo = walletInfo;
	// console.log(walletInfo?.compliance);
	return (
		<Styles>
			<Grid
				justifyContent={'space-around'}
				gap='10px'
				gridCol='repeat(auto-fit, minmax(200px, 1fr))'
				bgColor='#fff'
				pad='30px 20px'
			>
				<Card>
					<Flex
						className='container'
						alignItem={'center'}
						justifyContent={'space-between'}
					>
						<span>Email</span>
					</Flex>
					<Flex
						className='viewall'
						alignItem='center'
						justifyContent='flex-start'
						flexDir={'row'}
					>
						<span>{walletInfo?.compliance?.email}</span>
					</Flex>
				</Card>

				<Card>
					<Flex
						className='container'
						alignItem={'center'}
						justifyContent={'space-between'}
						style={{
							marginLeft: '10px'
						}}
					>
						<span>Reference ID</span>
					</Flex>
					<Flex
						className='viewall'
						alignItem='center'
						justifyContent='flex-start'
						flexDir={'row'}
						style={{
							marginLeft: '10px'
						}}
					>
						<span>{walletInfo?.wallet_reference}</span>
						<span
							className='copyBtn'
							// icon={<CopyIcon />}
							type='link'
							onClick={() => copyToClipboard(walletInfo?.wallet_reference)}
						>
							<CopyIcon />
						</span>
					</Flex>
				</Card>
				<Card>
					<Flex
						className='container'
						alignItem={'center'}
						justifyContent={'space-between'}
						style={{
							marginLeft: '10px'
						}}
					>
						<span>
							Compliance
							<BsFillPatchCheckFill
								color='#027200'
								style={{ marginLeft: '5px' }}
							/>
						</span>
					</Flex>
					<Flex
						className='viewall'
						alignItem='center'
						justifyContent='flex-start'
						flexDir={'row'}
						style={{
							marginLeft: '10px'
						}}
					>
						<Link
							to='/dashboard/wallets-compliance'
							style={{
								color: '#1a2cce',
								textDecoration: 'underline'
							}}
						>
							View compliance
						</Link>
					</Flex>
				</Card>
			</Grid>
		</Styles>
	);
};

export default Index;
