import { Button, Form, Input, Tooltip } from 'antd';
import React from 'react';
import QRCode from 'react-qr-code';
import { useMutation, useQueryClient } from 'react-query';
import { ScanQRCordIcon } from '../../../../assets';
import { Flex } from '../../../../components/box';
import CustomButton from '../../../../components/button';
import {
	approveTransaction,
	rejectTransaction,
	superApproveTransaction
} from '../../../../services/Approval.service';
import { resendEVMMerchant } from '../../../../services/Merchants.service';
import {
	addFreezeAmount,
	unFreezeAmount
} from '../../../../services/Others.service';
import {
	addApprover,
	addStaff,
	appConfig,
	editStaff,
	reset2fa,
	suspendStaff,
	unsuspendStaff
} from '../../../../services/Settings.service';
import { approveSwapTransaction } from '../../../../services/Transactions.service';
import { ToastMessage, type } from '../../../../utils/toastr';
import { ApprovedStyle } from './styles';

const ApproveTransactionSummary = ({
	handleQrScreen,
	id,
	setVisible,
	status,
	actionTaken,
	selectedIndex,
	swapAction,
	addEditData,
	showConfirmModal,
	appConfigData,
	resetForm,
	amountData,
	signatoryFormData
}) => {
	const queryClient = useQueryClient();

	const [form] = Form.useForm();
	const { TextArea } = Input;

	const ApprovalMutation = useMutation(
		async (data) => {
			await approveTransaction(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getAllPendingApprovals');
				queryClient.invalidateQueries('getPendingApprovalsDeposits');
				queryClient.invalidateQueries('getPendingApprovalsSwap');
				queryClient.invalidateQueries('getPendingApprovalsWithdrawals');
				queryClient.invalidateQueries('getPendingApprovalsFiat');
				queryClient.invalidateQueries('getPendingApprovalsCrypto');
				queryClient.invalidateQueries('getApprovalSummaryPending');
				queryClient.invalidateQueries('getApprovalSummaryRejected');
				queryClient.invalidateQueries('getApprovalSummaryApproved');
				queryClient.invalidateQueries('getTransactionsDeposit');
				ToastMessage(type.SUCCESS, 'Approved sucessfully');
				form.resetFields();
				setTimeout(() => {
					setVisible(false);
				}, 1000);
				form.resetFields();
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const SuperApprovalMutation = useMutation(
		async (data) => {
			await superApproveTransaction(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getAllPendingApprovals');
				queryClient.invalidateQueries('getPendingApprovalsDeposits');
				queryClient.invalidateQueries('getPendingApprovalsSwap');
				queryClient.invalidateQueries('getPendingApprovalsWithdrawals');
				queryClient.invalidateQueries('getPendingApprovalsFiat');
				queryClient.invalidateQueries('getPendingApprovalsCrypto');
				queryClient.invalidateQueries('getApprovalSummaryPending');
				queryClient.invalidateQueries('getApprovalSummaryRejected');
				queryClient.invalidateQueries('getApprovalSummaryApproved');
				queryClient.invalidateQueries('getTransactionsDeposit');
				ToastMessage(type.SUCCESS, 'Approved sucessfully');
				form.resetFields();
				setTimeout(() => {
					setVisible(false);
				}, 1000);
				form.resetFields();
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const ApprovalSwapMutation = useMutation(
		async (data) => {
			await approveSwapTransaction(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getTransactionsSwap');
				queryClient.invalidateQueries('getTransactionsDeposit');
				queryClient.invalidateQueries('getTransactionsWithdrawers');
				ToastMessage(type.SUCCESS, 'Approved successfully ');
				setTimeout(() => {
					setVisible(false);
				}, 1000);
				form.resetFields();
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const RejectSwapMutation = useMutation(
		async (data) => {
			await rejectTransaction(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getTransactionsSwap');
				queryClient.invalidateQueries('getTransactionsWithdrawers');
				queryClient.invalidateQueries('getTransactionsDeposit');
				ToastMessage(type.SUCCESS, 'Rejected successfully');
				setTimeout(() => {
					setVisible(false);
				}, 1000);
				form.resetFields();
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const RejectMutation = useMutation(
		async (data) => {
			await rejectTransaction(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getAllPendingApprovals');
				queryClient.invalidateQueries('getPendingApprovalsDeposits');
				queryClient.invalidateQueries('getPendingApprovalsSwap');
				queryClient.invalidateQueries('getPendingApprovalsWithdrawals');
				queryClient.invalidateQueries('getPendingApprovalsCrypto');
				queryClient.invalidateQueries('getPendingApprovalsFiat');
				queryClient.invalidateQueries('getApprovalSummaryPending');
				queryClient.invalidateQueries('getApprovalSummaryRejected');
				queryClient.invalidateQueries('getApprovalSummaryApproved');
				queryClient.invalidateQueries('getTransactionsDeposit');
				ToastMessage(type.SUCCESS, 'Rejected successfully');
				setTimeout(() => {
					setVisible(false);
				}, 1000);
				form.resetFields();
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const ResendEVMMutation = useMutation(
		async (data) => {
			await resendEVMMerchant(id, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['getAllMerchants']);
				ToastMessage(
					type.SUCCESS,
					'Merchant verification email resent successfully'
				);
				setTimeout(() => {
					setVisible(false);
				}, 1000);
			},
			onError: (error) => {
				console.log(error);
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	// Staff related request
	const addMutation = useMutation(
		async (data) => {
			await addStaff(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getStaffs');
				ToastMessage(type.SUCCESS, 'user has been sucessfully added');
				setVisible(false);
				showConfirmModal();
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const editStaffMutation = useMutation(
		async (data) => {
			await editStaff(data, id);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getStaffs');
				ToastMessage(type.SUCCESS, 'user info has been successfully edited');
				setVisible(false);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const reset2faMutation = useMutation(
		async (data) => {
			await reset2fa(id, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getStaffs');
				ToastMessage(type.SUCCESS, '2fa reset successfully');
				setVisible(false);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const suspendStaffMutation = useMutation(
		async (data) => {
			await suspendStaff(id, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getStaffs');
				ToastMessage(type.SUCCESS, 'Admin successfully suspended');
				setVisible(false);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const unsuspendStaffMutation = useMutation(
		async (data) => {
			await unsuspendStaff(id, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getStaffs');
				ToastMessage(type.SUCCESS, 'Admin successfully un-suspended');
				setVisible(false);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const appConfigMutation = useMutation(
		async (data) => {
			await appConfig(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getAppConfig');
				ToastMessage(type.SUCCESS, 'App configurations successfully saved');
				resetForm();
				setTimeout(() => {
					setVisible(false);
				}, 200);
				form.resetFields();
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const freezeMutation = useMutation(
		async (data) => {
			await addFreezeAmount(id, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getMerchantCurrencies');
				ToastMessage(type.SUCCESS, 'Balances frozen successfully');
				// resetForm();
				setTimeout(() => {
					setVisible(false);
				}, 200);
				// form.resetFields();
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const unfreezeMutation = useMutation(
		async (data) => {
			await unFreezeAmount(id, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getMerchantCurrencies');
				ToastMessage(type.SUCCESS, 'Balances unfrozen successfully');
				// resetForm();
				setTimeout(() => {
					setVisible(false);
				}, 200);
				// form.resetFields();
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);

	const mutation = useMutation(
		async (data) => {
			await addApprover(data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getApprovers');
				queryClient.invalidateQueries('getUserprofile');
				ToastMessage(type.SUCCESS, 'approver added successfully');
				setTimeout(() => setVisible(false), 1000);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const handleSubmit = (values) => {
		let payload;
		// selectedIndex !== [] ||
		if (selectedIndex !== undefined) {
			payload = {
				twoFactorAuthenticationCode: values.twoFactorAuthenticationCode,
				ids: selectedIndex,
				description: values.description
			};
		}

		if (id !== null) {
			payload = {
				twoFactorAuthenticationCode: values.twoFactorAuthenticationCode,
				ids: [id],
				description: values.description
			};
		}

		if (status === 'approve') {
			ApprovalMutation.mutate(payload);
		} else if (status === 'reject') {
			RejectMutation.mutate(payload);
		} else if (swapAction === 'accept') {
			ApprovalSwapMutation.mutate(payload);
		} else if (swapAction === 'reject') {
			RejectSwapMutation.mutate(payload);
		} else if (swapAction === 'super-approve') {
			SuperApprovalMutation.mutate(payload);
		} else if (swapAction === 'verify') {
			ResendEVMMutation.mutate({
				twoFactorAuthenticationCode: values.twoFactorAuthenticationCode
			});
		} else if (status === 'suspend') {
			suspendStaffMutation.mutate({
				twoFactorAuthenticationCode: values.twoFactorAuthenticationCode
			});
		} else if (status === 'unsuspend') {
			unsuspendStaffMutation.mutate({
				twoFactorAuthenticationCode: values.twoFactorAuthenticationCode
			});
		} else if (status === 'reset') {
			reset2faMutation.mutate({
				twoFactorAuthenticationCode: values.twoFactorAuthenticationCode
			});
		} else if (status === 'add') {
			addEditData.twoFactorAuthenticationCode =
				values.twoFactorAuthenticationCode;
			addMutation.mutate(addEditData);
		} else if (status === 'edit') {
			addEditData.twoFactorAuthenticationCode =
				values.twoFactorAuthenticationCode;
			editStaffMutation.mutate(addEditData);
		} else if (status === 'app_config') {
			let formData = new FormData();
			formData.append('logo', appConfigData?.logo);
			formData.append('alt_logo', appConfigData?.alt_logo);
			formData.append('favicon', appConfigData?.favicon);
			formData.append('logo_icon', appConfigData?.logo_icon);
			formData.append('email_logo', appConfigData?.email_logo);
			formData.append('name', appConfigData?.name);
			formData.append('website', appConfigData?.website);
			formData.append('title', appConfigData?.title);
			formData.append('admin_email', appConfigData?.admin_email);
			formData.append('api_base_url', appConfigData?.api_base_url);
			formData.append('merchant_url', appConfigData?.merchant_url);
			formData.append('support_email', appConfigData?.support_email);
			formData.append('support_phone', appConfigData?.support_phone);
			formData.append('support_url', appConfigData?.support_url);
			formData.append(
				'twoFactorAuthenticationCode',
				values?.twoFactorAuthenticationCode
			);

			appConfigMutation.mutate(formData);
		} else if (status === 'freeze') {
			freezeMutation.mutate({
				twoFactorAuthenticationCode: values.twoFactorAuthenticationCode,
				amount: amountData.amount,
				currencyCode: amountData.code
			});
		} else if (status === 'unfreeze') {
			unfreezeMutation.mutate({
				twoFactorAuthenticationCode: values.twoFactorAuthenticationCode,
				amount: amountData.amount,
				currencyCode: amountData.code
			});
		} else if (status === 'initiate/approve') {
			mutation.mutate({
				twoFactorAuthenticationCode: values.twoFactorAuthenticationCode,
				type: signatoryFormData.type,
				userId: signatoryFormData.userId
			});
			// console.log({
			// 	twoFactorAuthenticationCode: values.twoFactorAuthenticationCode,
			// 	type: signatoryFormData.type,
			// 	userId: signatoryFormData.userId
			// });
		}
	};

	return (
		<ApprovedStyle>
			<Flex justifyContent='space-between' alignItems='baseline'>
				<h3>
					{status === 'approve' || swapAction === 'super-approve'
						? 'Approve Transactions'
						: status === 'reject' || swapAction === 'super-reject'
							? 'Reject Transactions'
							: actionTaken === 'approve'
								? 'Approve Transactions'
								: actionTaken === 'reject'
									? 'Reject Transactions'
									: swapAction === 'accept'
										? 'Accept Transactions'
										: swapAction === 'reject'
											? 'Reject Transaction'
											: swapAction === 'verify'
												? 'Send verification email'
												: status === 'unsuspend'
													? 'Unsuspend user'
													: status === 'suspend'
														? 'Suspend user'
														: status === 'reset'
															? 'Reset 2FA'
															: status === 'add'
																? 'Invite new member'
																: status === 'edit'
																	? 'Edit member'
																	: status === 'app_config'
																		? 'Edit app configurations'
																		: status === 'freeze'
																			? 'Freeze'
																			: status === 'unfreeze'
																				? 'Unfreeze'
																				: status === 'initiate/approve'
																					? 'Add signatory'
																					: ''}
				</h3>
			</Flex>
			<p>
				{` To be able to ${status === 'approve' || swapAction === 'super-approve'
					? 'Approve Transactions'
					: status === 'reject' || swapAction === 'super-reject'
						? 'Reject Transactions'
						: actionTaken === 'approve'
							? 'Approve Transactions'
							: actionTaken === 'reject'
								? 'Reject Transactions'
								: swapAction === 'accept'
									? 'Accept Transactions'
									: swapAction === 'reject'
										? 'Reject Transaction'
										: swapAction === 'verify'
											? 'Resend verification email'
											: status === 'suspend'
												? 'Suspend user'
												: status === 'unsuspend'
													? 'Unsuspend user'
													: status === 'reset'
														? 'Reset 2FA'
														: status === 'add'
															? 'Invite new member'
															: status === 'edit'
																? 'Edit member'
																: status === 'app_config'
																	? 'Edit app configurations'
																	: status === 'freeze'
																		? 'Freeze'
																		: status === 'unfreeze'
																			? 'Unfreeze'
																			: status === 'initiate/approve'
																				? 'Add signatory'
																				: ''
					}, you need to enter the verification
        code generated on the app or scan the QR code`}
			</p>

			<Form
				style={{ width: '100%' }}
				form={form}
				layout='vertical'
				onFinish={handleSubmit}
			// justifyContent='space-between'
			>
				<Flex flexDir='column' style={{ width: '100%' }}>
					{status === 'reject' ||
						actionTaken === 'reject' ||
						swapAction === 'reject' ? (
						<Form.Item
							label='Reason for rejection'
							rules={[
								{
									required: false
									// message: 'State reason for rejection is Required'
								}
							]}
							id='desc'
							style={{ width: '100%' }}
							name='description'
						>
							<TextArea
								id='desc'
								rows={3}
								placeholder='State reason for rejection'
								style={{ height: '100%' }}
							/>
						</Form.Item>
					) : null}
					<Flex style={{ width: '100%' }}>
						<Form.Item
							label='Paste 2fa code'
							name='twoFactorAuthenticationCode'
							style={{ width: '100%' }}
							rules={[
								{
									required: true,
									message: '2FA code is Required'
								}
							]}
						>
							<Input bordered={false} />
						</Form.Item>
						<Tooltip title='scan QR code'>
							<Button
								icon={<ScanQRCordIcon width='16' />}
								onClick={handleQrScreen}
								width='10%'
								type='button'
							/>
						</Tooltip>
					</Flex>
				</Flex>

				<CustomButton
					Styles
					text={'Verify code'}
					bgColor={'#1A2CCE'}
					color={'#FFFFFF'}
					size='md'
					fullwidth
					type='submit'
					loading={
						ApprovalMutation.isLoading ||
						RejectMutation.isLoading ||
						ApprovalSwapMutation.isLoading ||
						RejectSwapMutation.isLoading ||
						ResendEVMMutation.isLoading ||
						appConfigMutation.isLoading ||
						freezeMutation.isLoading ||
						unfreezeMutation.isLoading ||
						mutation.isLoading
					}
				/>
			</Form>
		</ApprovedStyle>
	);
};

const QRScreen = () => (
	<ApprovedStyle>
		<Flex justifyContent='space-between' alignItems='baseline'>
			<h3>Scan QR code</h3>
			<h4>10:00</h4>
		</Flex>
		<Flex className='code'>
			<QRCode value={'184bhjds5676329090721'} />
		</Flex>
	</ApprovedStyle>
);

export { ApproveTransactionSummary, QRScreen };
