import { Tabs } from 'antd';
import React from 'react';
import { Flex } from '../../../../components/box';
import { Container1, Styles } from './styles';

import { useQuery } from 'react-query';
import { Loader } from '../../../../components/loader';
import { Spacer } from '../../../../components/spacer/styles';
import { useAuthState } from '../../../../context';
import { getApprovers } from '../../../../services/Settings.service';
import AppConfig from './appConfig';
import Approvals from './approvals';
import CurrencyPair from './currency-pairs';
import MarketRate from './market-rate';
import SettlementTime from './settlement-time';
import SupportedCurrencies from './supported-currencies';
// import ProcessorBalance from './processorBalance/ProcessorBalance';
import Wallet from './wallet/Wallet';

const Index = () => {
	const { TabPane } = Tabs;
	const { settings } = useAuthState();

	const result = useQuery('getApprovers', async () => await getApprovers());

	const tabLink = [
		{
			path: 'App Config',
			component: <AppConfig />
		},
		{
			path: 'Approvals',
			component: <Approvals settings={settings} result={result} />
		},

		{
			path: 'Market settings',
			component: <MarketRate settings={settings} />
		},
		{
			path: 'Supported currencies',
			component: <SupportedCurrencies />
		},
		{
			path: 'Settlement time',
			component: <SettlementTime />
		},
		{
			path: 'Currency pairs',
			component: <CurrencyPair />
		},
		// {
		// 	path: 'Provider balance',
		// 	component: <ProcessorBalance />
		// }
		{
			path: 'Wallet',
			component: <Wallet status={settings?.validate_wallet_compliance} />
		}
	];

	if (result.isLoading) {
		return <Loader />;
	}

	if (result.isError) {
		return <span>Error: {result?.error.message}</span>;
	}
	return (
		<>
			<Styles>
				<Container1>
					<Flex justifyContent='space-between' flex-direction='row'>
						<h2>Manage and control global settings across the platform here</h2>
					</Flex>
				</Container1>
				<Spacer height='30px' />

				<Tabs defaultActiveKey='0' tabPosition={'left'} id='general-tabs'>
					{tabLink?.map((data, index) => (
						<TabPane tab={data.path} key={index}>
							{data.component}
						</TabPane>
					))}
				</Tabs>
			</Styles>
		</>
	);
};

export default Index;
