import { Form } from "antd";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";

import { Styles } from "./styles";
import CustomTable from "./table/index";
import Summary from "./summary";
import { BackIcon } from "../../../../assets/index";
import { Flex } from "../../../../components/box";
import Button from "../../../../components/button/index";
import Layout from "../../../../components/layouts/index";
import TopUp from "../../../../components/topUp/TopUp";
import { Loader } from "../../../../components/loader/index";
import { settlementHead } from "../../../../utils/constants";
import { Spacer } from "../../../../components/spacer/styles";
import ModalFrame from "../../../../components/modal/ModalFrame";
import DownloadReport from "../../../../components/downloadReport/DownloadReport";

import {
  getLiquidityById,
  getLiquidityReport,
} from "../../../../services/Liquidity.service";

const Index = () => {
  const { overdraft } = useParams();

  const [filter, setFilter] = useState({ page: 1 });

  const result = useQuery(
    "getLiquidityById",
    async () => await getLiquidityById(overdraft, filter)
  );

  const [downloadVisible, setDownloadVisible] = useState(false);
  const [topUpVisible, setTopUpVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [activeSummaryOption, setActiveSummaryOption] = useState("usdt");
  const [fileType, setFileType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [confirmLoading, setConfirmLoading] = useState();
  const [form] = Form.useForm();

  const handleOk = () => {
    setConfirmLoading(true);

    getLiquidityReport(overdraft, fromDate, toDate)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(`download`, `report.${fileType.toLowerCase()}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.response) {
        } else if (error.request) {
        } else {
        }
      });

    setTimeout(() => {
      setDownloadVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setDownloadVisible(false);
    setTopUpVisible(false);
    setDeleteVisible(false);
  };

  const handleSetSummaryOption = (activeSummaryOption) => {
    setActiveSummaryOption(activeSummaryOption);
  };

  const handleShowDelete = () => {
    setDeleteVisible(true);
  };

  const handleDownload = () => {
    setDownloadVisible(true);
  };

  const handlePageChange = (page) => {
    setFilter({ ...filter, page: page });
    setTimeout(() => result.refetch());
  };

  if (result.status === "loading") {
    return <Loader full />;
  }

  if (result.status === "error") {
    return <span>Error: {result.error.message}</span>;
  }

  const singleLiquidity = result?.data?.data?.data;

  return (
    <>
      <Styles>
        <Layout bgColor="#fff" title={"Liquidity"}>
          <Spacer height="20px" />
          <Flex justifyContent="space-between" alignItems="center">
            <Link className="back_link" to={"/dashboard/liquidity"}>
              <BackIcon />
              <p style={{ marginLeft: "10px" }}>Back to liquidity</p>
            </Link>
            <Flex width="20%" justifyContent="space-around"></Flex>
          </Flex>
          <Spacer height="20px" />
          <Summary
            activeSummaryOption={activeSummaryOption}
            handleSetSummaryOption={handleSetSummaryOption}
            handleDownload={handleDownload}
            liquidity={singleLiquidity?.liquidity}
            handleCancel={handleCancel}
            handleDelete={handleShowDelete}
            handleOk={handleOk}
            deleteVisible={deleteVisible}
          />
          <Spacer height="20px" />
          <CustomTable
            theadBkColor="#F1F4F8"
            bottomGap="10px"
            tableHead={settlementHead}
            tableBody={singleLiquidity?.transactions?.data}
            rowHovColor="#d2ccc626"
            theadColor="#8e8e8e"
            tbodyColor="#141414"
            handleReadAll={() => []}
            meta={singleLiquidity?.transactions?.meta}
            handlePageChange={handlePageChange}
            paginator
          />
        </Layout>
      </Styles>

      <ModalFrame
        visible={downloadVisible}
        handleOk={handleOk}
        confirmLoading={confirmLoading}
        handleCancel={handleCancel}
        footer={null}
      >
        <h3 style={{ textAlign: "left" }}>Download Report</h3>

        <DownloadReport
          setFileType={setFileType}
          handleOk={handleOk}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />
      </ModalFrame>

      <ModalFrame
        visible={topUpVisible}
        handleCancel={handleCancel}
        footer={[
          <Button
            Styles
            text={"Cancel"}
            bgColor={"#ffffff"}
            size="md"
            type="button"
            onClick={handleCancel}
          />,
          <Button
            Styles
            text={"Confirm"}
            bgColor={"#1A2CCE"}
            color="#FFFFFF"
            size="md"
            type="button"
            onClick={handleOk}
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          />,
        ]}
      >
        <TopUp form={form} id={overdraft} setTopUpVisible={setTopUpVisible} />
      </ModalFrame>
    </>
  );
};

export default Index;
