import axios from "axios";
import { ToastMessage, type } from "../utils/toastr";

const url = process.env.REACT_APP_BASE_URL;

//  {
//   production: "https://api.trytreasura.com",
//   dev: "https://api.staging.trytreasura.com",
// };

const api = axios.create({
  baseURL: url,
  headers: {
    "Content-type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const handleSetToast = () => {
  ToastMessage(type.ERROR, "Something went wrong, please login again");
};

window.onload = () => {
  const reloading = sessionStorage.getItem("reloading");
  if (reloading) {
    sessionStorage.removeItem("reloading");
    handleSetToast();
  }
};

const handleLogout = () => {
  localStorage.clear();
  sessionStorage.setItem("reloading", "true");
  window.location.reload(false);
};

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      console.log(
        error.response?.data,
        error.response.data.message,
        error.response.status
      );
      if (
        error.response.status === 403 &&
        error.response.data.message === "Wrong authentication code"
      ) {
        // console.log(error.response.data.message);
      }
      if (
        error.response.status === 401 ||
        error.response.data.message === "Unauthorized" ||
        error.response.data.message === "Session expired"
      ) {
        handleLogout();
      }
    } else if (error.request) {
    } else {
    }

    return Promise.reject(error);
  }
);

export default api;
