import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Flex } from '../../../components/box';
import Button from '../../../components/button/index';
import {
	FieldWrapper,
	InputStyle,
	Label
} from '../../../components/input/styles';
import { twoFA } from '../../../services/Auth.service';
import { ToastMessage, type } from '../../../utils/toastr';
import { ForgotPasswordLink, FormContainer } from './styles';

const Fields = () => {
	const initialValues = {
		twoFactorAuthenticationCode: ''
	};

	const validateSchema = yup.object().shape({
		twoFactorAuthenticationCode: yup
			.string()
			.required('please provide your 2fa code')
	});

	let navigate = useNavigate();

	return (
		<FormContainer>
			<Formik
				initialValues={initialValues}
				onSubmit={async (values, { setSubmitting, resetForm }) => {
					try {
						const success = await twoFA(values);
						if (success)
							navigate({
								pathname: '/dashboard'
							});
						ToastMessage(type.SUCCESS, success?.data?.message);
						if (success?.data?.data?.access_token.length > 0) {
							localStorage.setItem(
								'access_token',
								success?.data?.data?.access_token
							);
						}
					} catch (error) {
						ToastMessage(type.ERROR, error.response.data.message);
					}
				}}
				validationSchema={validateSchema}
			>
				{({
					isSubmitting,
					handleChange,
					values: { twoFactorAuthenticationCode },
					handleBlur
				}) => {
					return (
						<Form>
							<Flex flexDir={'column'} gap={'30px'}>
								<InputStyle>
									<Label htmlFor='twoFactorAuthenticationCode'>
										2fa login code
									</Label>
									<FieldWrapper>
										<Field
											type={'text'}
											onChange={handleChange}
											name={'twoFactorAuthenticationCode'}
											value={twoFactorAuthenticationCode}
											onBlur={handleBlur}
										/>
									</FieldWrapper>
									<ErrorMessage
										name={'twoFactorAuthenticationCode'}
										component={'div'}
									/>
								</InputStyle>
								<Button
									text={'Verify'}
									bgColor={'#1A2CCE'}
									color={'#fff'}
									size='md'
									fullwidth
									type='submit'
									borderRadius={'5px'}
									loading={isSubmitting}
								/>
								<ForgotPasswordLink>
									<Link to={'/'}>Return to Login</Link>
								</ForgotPasswordLink>
							</Flex>
						</Form>
					);
				}}
			</Formik>
		</FormContainer>
	);
};

export default Fields;
