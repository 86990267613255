import React from 'react';
import AllPending from './allPending';
import Swap from './swap';
import Deposits from './deposit';
import Withdrawal from './withdrawal';
import Crypto from './crypto';
import { Styles } from './styles';
import Overview from '../overveiw';
import Layout from '../../../../components/layouts';
import { Spacer } from '../../../../components/spacer/styles';
import RouterTabs from '../../../../components/Tabs/routerTab';
import {
	getApprovalSummaryApproved,
	getApprovalSummaryFeeCollected,
	getApprovalSummaryPending,
	getApprovalSummaryRejected
} from '../../../../services/Approval.service';
import { useQuery } from 'react-query';

const ApprovalsPending = () => {
	const pending = useQuery(
		'getApprovalSummaryPending',
		async () => await getApprovalSummaryPending()
	);

	const approved = useQuery(
		'getApprovalSummaryApproved',
		async () => await getApprovalSummaryApproved()
	);

	const rejected = useQuery(
		'getApprovalSummaryRejected',
		async () => await getApprovalSummaryRejected()
	);

	const feeCollected = useQuery(
		'getApprovalSummaryFeeCollected',
		async () => await getApprovalSummaryFeeCollected()
	);

	const links = [
		{
			path: '/dashboard/approvals/pending',
			title: 'All'
		},
		{
			path: '/dashboard/approvals/pending/swap',
			title: 'Swap'
		},
		{
			path: '/dashboard/approvals/pending/deposits',
			title: 'Deposits'
		},
		{
			path: '/dashboard/approvals/pending/withdrawals',
			title: 'Withdrawals'
		}
	];

	const routes = [
		{
			path: '/',
			component: <AllPending />
		},
		{
			path: '/swap',
			component: <Swap />
		},
		{
			path: '/deposits',
			component: <Deposits />
		},
		{
			path: '/withdrawals',
			component: <Withdrawal />
		}
	];

	return (
		<Layout title={'Approvals'}>
			<Styles>
				<Overview
					pending={pending?.data?.data?.data}
					approved={approved?.data?.data?.data}
					rejected={rejected?.data?.data?.data}
					feeCollected={feeCollected?.data?.data?.data}
					loading={
						pending.isLoading ||
						rejected.isLoading ||
						feeCollected.isLoading ||
						approved.isLoading
					}
				/>
				<Spacer height='25px' />
				<h2>Pending Approvals</h2>
				<Spacer height='20px' />
				<RouterTabs type='primary-tab' routes={routes} links={links} />
			</Styles>
		</Layout>
	);
};

export default ApprovalsPending;
