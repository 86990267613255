import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from '../../../components//layouts/index';
import RouterTabs from '../../../components/Tabs/routerTab';
import { Spacer } from '../../../components/spacer/styles';
import { useAuthState } from '../../../context';
import { RoleCheck } from '../../../routes/privateRoute';
import CrossFees from './cross-fees';
// import General from './general';
import PaymentMethods from './payment-method';

import { Styles } from './styles';

const Index = () => {
	const { state } = useAuthState();
	const location = useLocation();

	const [role, setRole] = useState();
	const [permissions, setPermissions] = useState();

	useEffect(() => {
		setRole(state?.user?.role?.name);
		setPermissions(state?.user?.role?.permitions);
	}, [state]);

	const links = [
		{
			path: '/dashboard/fees',
			title: 'Payment methods'
		},

		{
			path: '/dashboard/fees/cross-fees',
			title: 'Cross Fees'
		}
	];

	const routes = [
		// {
		// 	path: '/',
		// 	component: <Profile />
		// },
		// {
		// 	path: '/general',
		// 	component: (
		// 		<RoleCheck role={role !== 'Super Admin' ? false : true}>
		// 			<General />
		// 		</RoleCheck>
		// 	)
		// },
		{
			path: '/',
			component: (
				<RoleCheck role={role !== 'Super Admin' ? false : true}>
					<PaymentMethods />
				</RoleCheck>
			)
		},
		// {
		// 	path: '/teams',
		// 	component: (
		// 		<RoleCheck
		// 			role={role !== 'Super Admin' ? false : true}
		// 			// role={(role === 'Super Admin' && true) || (role === 'Admin' && true)}
		// 		>
		// 			<UserRoles />
		// 		</RoleCheck>
		// 	)
		// },
		{
			path: '/cross-fees',
			component: <CrossFees />
		}
		// {
		// 	path: '/xchange-connect',
		// 	component: <XchangeConnect />
		// },
		// {
		// 	path: '/security',
		// 	component: <Security />
		// },
		// {
		// 	path: '/security/activities',
		// 	component: <ActivitiesLog />
		// },
		// {
		// 	path: '/security/session',
		// 	component: <ManageSession />
		// }
	];

	return (
		<Layout title={'Fees'}>
			<Styles>
				<Spacer height='30px' />

				{role === 'Super Admin' && permissions?.includes('all') ? (
					<RouterTabs type='primary-tab' routes={routes} links={links} />
				) : null}
				{/* {role === 'Admin' && permissions?.includes('merchants') ? (
					<RouterTabs
						type='primary-tab'
						routes={routes}
						links={[links[0], links[4]]}
					/>
				) : null}
				{role === 'Treasury' && (
					<RouterTabs
						type='primary-tab'
						routes={routes}
						links={[links[0], links[4]]}
					/>
				)}
				{role === 'Finance' && (
					<RouterTabs
						type='primary-tab'
						routes={routes}
						links={[links[0], links[4]]}
					/>
				)}
				{role === 'Compliance' && (
					<RouterTabs
						type='primary-tab'
						routes={routes}
						links={[links[0], links[4]]}
					/>
				)}
				{role === 'Support' && (
					<RouterTabs
						type='primary-tab'
						routes={routes}
						links={[links[0], links[4]]}
					/>
				)} */}
			</Styles>
		</Layout>
	);
};

export default Index;
