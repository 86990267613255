import React from 'react';
import RouterTabs from '../../../components/Tabs/routerTab';
import Layout from '../../../components/layouts/index';
import { Spacer } from '../../../components/spacer/styles';
import Pending from './pending';
import Suspended from './rejected';
import { Styles } from './styles';
import Approved from './verified';

const Index = () => {
	const links = [
		{
			path: '/dashboard/compliance',
			title: 'Pending'
		},
		{
			path: '/dashboard/compliance/approved',
			title: 'Verified'
		},
		{
			path: '/dashboard/compliance/suspended',
			title: 'Rejected'
		}
	];

	const routes = [
		{
			path: '/',
			component: <Pending />
		},
		{
			path: '/approved',
			component: <Approved />
		},
		{
			path: '/suspended',
			component: <Suspended />
		}
	];

	return (
		<Layout title={"Merchant's Compliance"}>
			<Styles>
				<Spacer height='20px' />
				<RouterTabs
					routes={routes}
					links={links}
				// type='transactions'
				/>
			</Styles>
		</Layout>
	);
};

export default Index;
