import React, { createContext, useContext, useEffect, useReducer } from "react";
import { useQuery } from "react-query";
import { getAllCurrencyPair, getCountries } from "../services/Others.service";
import {
  getAllCurrencies,
  getSettings,
  getUserprofile,
} from "../services/Settings.service";
import { AuthReducer, initialState } from "./reducer";
import { LOGIN_SUCCESS, LOGOUT } from "./types";

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

function AuthProvider(props) {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const [currencies, setCurrencies] = React.useState([]);
  const [currencyPair, setCurrencyPair] = React.useState([]);
  const [settings, setGetSettings] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [checkToken, setCheckToken] = React.useState("");

  useQuery(["getSettings", checkToken], async () => await getSettings(), {
    onSuccess: (res) => {
      setGetSettings(res?.data?.data);
    },
    onError: (error) => <p> {error.response.data.message}</p>,
    refetchOnMount: "always",
    enabled: !!checkToken,
  });

  useQuery(["getUserprofile", checkToken], async () => await getUserprofile(), {
    onSuccess: (res) => {
      dispatch({ type: LOGIN_SUCCESS, payload: res?.data?.data });
    },
    refetchOnMount: "always",
    enabled: !!checkToken,
  });

  useQuery(
    ["getAllCurrencies", checkToken],
    async () => await getAllCurrencies(),
    {
      onSuccess: (res) => {
        setCurrencies(res?.data?.data);
      },
      onError: (error) => <p> {error.response.data.message}</p>,
      refetchOnMount: "always",
      enabled: !!checkToken,
    }
  );

  useQuery(
    ["getAllCurrencyPair", checkToken],
    async () => await getAllCurrencyPair(),
    {
      onSuccess: (res) => {
        setCurrencyPair(res?.data?.data);
      },
      onError: (error) => <p> {error.response.data.message}</p>,
      refetchOnMount: "always",
      enabled: !!checkToken,
    }
  );

  useQuery(["getCountries", checkToken], async () => await getCountries(), {
    refetchOnMount: "always",
    onSuccess: (res) => {
      setCountries(res?.data);
    },
    onError: (error) => <p> {error.response.data.message}</p>,
    enabled: !!checkToken,
  });

  useEffect(() => {
    setCheckToken(localStorage.getItem("access_token"));
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    dispatch({ type: LOGOUT });
  };

  return (
    <AuthStateContext.Provider
      value={{
        countries,
        settings,
        currencies,
        currencyPair,
        state,
        handleLogout,
      }}
    >
      <AuthDispatchContext.Provider value={dispatch}>
        {props.children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

function useAuthState() {
  const context = useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }

  return context;
}

function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);

  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvide");
  }

  return context;
}

export { AuthProvider, useAuthDispatch, useAuthState };
