import { Input } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Flex } from '../../../../components/box';
import Button from '../../../../components/button/index';
import { Loader } from '../../../../components/loader/index';
import ModalFrame from '../../../../components/modal/ModalFrame';
import {
	add,
	getInterconnected,
	switchInterconnected
} from '../../../../services/Settings.service';
import { XchangeConnectTableHead } from '../../../../utils/constants';
import { ToastMessage, type } from '../../../../utils/toastr';
import { FormLayout } from './form';
import { Container1, FormContainer, Styles } from './styles';
import CustomTable from './table/index';

const XchangeConnect = () => {
	const queryClient = useQueryClient();

	const interconnected = useQuery(
		'getInterconnected',
		async () => await getInterconnected(filter)
	);
	const [action, setAction] = React.useState('add');
	const [visible, setVisible] = React.useState(false);
	const [confirmVisible, setConfirmVisible] = React.useState(false);
	const [status, setStatus] = React.useState(false);
	const [editVisible, setEditVisible] = React.useState(false);

	const [filter, setFilter] = React.useState({
		page: 1
	});
	const [merchantId, setMerchantId] = React.useState(Number);

	const [imgUpload, setImgUpload] = useState(null);
	const [clicked, setClicked] = useState('false');
	const [reference, setReference] = useState('');

	const addInterconnectedMutation = useMutation(
		async (data) => {
			await add(merchantId, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getInterconnected');
				ToastMessage(type.SUCCESS, 'Merchant added successfully');
				setTimeout(() => {
					setVisible(false);
					setMerchantId(Number);
				}, 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	const switchInterconnectedMutation = useMutation(
		async (data) => {
			await switchInterconnected(merchantId, data);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('getInterconnected');
				ToastMessage(
					type.SUCCESS,
					'Merchant interconnected status switched successfully'
				);
				setTimeout(() => {
					setConfirmVisible(false);
					setMerchantId(Number);
					setStatus(false);
				}, 500);
			},
			onError: (error) => {
				ToastMessage(type.ERROR, error.response.data.message);
			}
		}
	);
	// const deleteStaffMutation = useMutation(
	// 	async (data) => {
	// 		await deleteStaff(data);
	// 	},
	// 	{
	// 		onSuccess: () => {
	// 			queryClient.invalidateQueries('getStaffs');
	// 			ToastMessage(type.SUCCESS, 'Admin sucessfully deleted');
	// 			setDeleteVisible(false);
	// 		},
	// 		onError: (error) => {
	// 			ToastMessage(type.ERROR, error.response.data.message);
	// 		}
	// 	}
	// );

	const showModal = () => {
		setVisible(true);
	};

	const showConfirmModal = (id, status) => {
		setMerchantId(id);
		setStatus(status);
		setConfirmVisible(true);
	};

	const handleCancel = () => {
		setVisible(false);
		setEditVisible(false);
		setConfirmVisible(false);
		setAction('add');
	};

	const handlePageChange = (page) => {
		setFilter({ ...filter, page: page });
		setTimeout(() => interconnected.refetch());
	};
	const handleSubmitFilter = () => {
		if (filter.currency === 'All') {
			delete filter.currency;
		}
		if (filter.type === 'All') {
			delete filter.status;
		}
		setClicked('true');
		interconnected.refetch(filter);
	};

	const handleEnableDisable = () => {
		if (status === true) {
			switchInterconnectedMutation?.mutate({ status: false });
		} else if (status === false) {
			switchInterconnectedMutation?.mutate({ status: true });
		}
	};

	const handleSubmit = (values) => {
		let formData = new FormData();
		formData.append('interconnected_logo', imgUpload);
		formData.append('interconnected_name', values?.name?.label);
		formData.append('interconnected_reference', reference);
		// for (const pair of formData.entries()) {
		// 	console.log(`${pair[0]}, ${pair[1]}`);
		// }

		addInterconnectedMutation.mutate(formData);
	};

	if (interconnected.status === 'error') {
		return <span>Error: {interconnected.error.message}</span>;
	}

	return (
		<>
			<Styles>
				<Container1>
					<Flex
						flexDir='row'
						justifyContent='space-between'
						className='method'
						alignItems='center'
						style={{
							marginTop: '44px',
							marginBottom: '21px'
						}}
					>
						<Flex justifyContent='start' gap={40}>
							<Input
								placeholder='Search'
								size='large'
								style={{
									width: '271px'
								}}
							/>
							{/* <Flex width='25%'>
								<Input.Group
									compact
									style={{
										width: '271px'
									}}
								>
									<Input
										placeholder='Sort By'
										disabled
										style={{
											width: '30%'
										}}
									/>
									<Select
										defaultValue='All'
										style={{
											height: '46px !important',
											minWidth: '60%',
											maxWidth: '60%'
										}}
									>
										<Option
											style={{
												height: '46px !important'
											}}
											value=''
										>
											All
										</Option>
										<Option
											style={{
												height: '46px !important'
											}}
											value='transaction_type'
										>
											Transaction Type
										</Option>
										<Option
											style={{
												height: '46px !important'
											}}
											value='fee_currency'
										>
											Fee Currency
										</Option>
									</Select>
								</Input.Group>
							</Flex> */}
						</Flex>
						<Button
							Styles
							text={'New Xchange'}
							bgColor={'#1A2CCE'}
							color={'#FFFFFF'}
							size='md'
							type='submit'
							border={'0.7px solid #BFBFBF'}
							boxShadow={'0px 2px 10px rgba(0, 0, 0, 0.08)'}
							borderRadius={'3px'}
							onClick={showModal}
						/>
					</Flex>
				</Container1>

				{interconnected?.isFetching ? (
					<Loader />
				) : (
					<CustomTable
						theadBkColor='#F1F4F8'
						bottomGap='10px'
						tableHead={XchangeConnectTableHead}
						tableBody={interconnected?.data?.data?.data?.data}
						rowHovColor='#d2ccc626'
						theadColor='#8e8e8e'
						tbodyColor='#141414'
						handleReadAll={() => []}
						pageSize={5}
						// handleEdit={handleEdit}

						handlePageChange={handlePageChange}
						paginator
						meta={interconnected?.data?.data?.data?.meta}
						handleEnableDisable={showConfirmModal}
					/>
				)}
			</Styles>
			<ModalFrame
				visible={action === 'add' ? visible : editVisible}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						bgColor={'#ffffff'}
						size='md'
						type='button'
						onClick={handleCancel}
						key='back'
					/>,
					<Button
						Styles
						text={'Confirm'}
						bgColor={'#1A2CCE'}
						color={'#FFFFFF'}
						// fullwidth
						size='md'
						type='submit'
						border={'0.7px solid #BFBFBF'}
						boxShadow={'0px 2px 10px rgba(0, 0, 0, 0.08)'}
						borderRadius={'3px'}
						id='cross-fee'
						key='submit'
						form='xchange'
						loading={addInterconnectedMutation.isLoading}
					/>
				]}
				destroyOnClose={true}
			>
				<FormContainer>
					<h3 style={{ textAlign: 'left' }}>
						Add Xchange
						{/* {action === 'add' ? 'Add Xchange' : 'Edit Xchange'} */}
					</h3>
					<FormLayout
						setEditVisible={setEditVisible}
						setVisible={setVisible}
						action={action}
						setMerchantId={setMerchantId}
						handleSubmit={handleSubmit}
						setImgUpload={setImgUpload}
						iRefVal={setReference}
					/>
				</FormContainer>
			</ModalFrame>

			<ModalFrame
				visible={confirmVisible}
				handleCancel={handleCancel}
				footer={[
					<Button
						Styles
						text={'Cancel'}
						color={'#404040'}
						bgColor={'#FFFFFF'}
						size='md'
						type='button'
						border={'0.7px solid #BFBFBF'}
						onClick={handleCancel}
						marginRight={5}
					/>,
					<Button
						Styles
						text={'Confirm'}
						bgColor={'#1A2CCE'}
						color={'#FFFFFF'}
						size='md'
						type='button'
						onClick={handleEnableDisable}
						loading={switchInterconnectedMutation.isLoading}
					/>
				]}
			>
				<h3 style={{ textAlign: 'center' }}>Confirm switch</h3>
				<p>
					Are you sure you want to {status === true ? 'disable' : 'enable'}?
				</p>
			</ModalFrame>
		</>
	);
};

export default XchangeConnect;
